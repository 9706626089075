import { useEffect, useState } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: initial;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`

const TOTAL_FILES = 26

function getRandomAnim() {
  return `/images/travel-animations/travel${(
    Math.random() * TOTAL_FILES
  ).toFixed()}.gif`
}

export default function TravelBackground({ progress }: { progress: number }) {
  const [currentAnim, setCurrentAnim] = useState(getRandomAnim())

  useEffect(() => {
    setCurrentAnim(getRandomAnim)
  }, [progress])

  return (
    <Container>
      <img src={currentAnim} width="100%" height="auto" />
    </Container>
  )
}
