import { useState } from 'react'

import client, { Bot } from '@pxlbots/sdk'
import { BigNumber } from 'ethers'
import { parseEther } from 'ethers/lib/utils'
import { FieldValue, Validation } from 'formact'
import styled from 'styled-components'

import { useGame } from '../context/GameContext'
import Modal, { ModalInput } from './Modal'
const Content = styled.div`
  flex-direction: row;
  max-width: 800px;
  flex: 1;
  align-items: center;
`

type Form = {
  amount: string
}

const VALIDATE_LOCATION: Validation = (location: FieldValue) => {
  if (location) {
    const number = Number(location)

    if (isNaN(number)) {
      return 'Please enter a valid number'
    }

    if (number <= 0) {
      return 'Must be greather than 0'
    }
  }
}

export default function ClaimModal(props: {
  bot?: Bot
  onClose: () => any
  open?: boolean
}) {
  const { updateBots, updateBalance } = useGame()
  const [submitting, setSubmitting] = useState(false)

  return (
    <Modal<Form>
      open={props.open}
      onClose={submitting ? () => {} : props.onClose}
      title="Transfer"
      subtitle="PXL"
      buttonLabel="Transfer to Wallet"
      form={{
        initialValues: {
          amount: Number(props.bot?.inventory?.pxl).toString() || '0',
        },
        onSubmit: async (payload) => {
          try {
            if (props.bot && payload.valid) {
              setSubmitting(true)

              const amount: BigNumber = parseEther(payload.values.amount)
              await client.bots.claim(props.bot.tokenId, amount)
              await updateBots()
              await updateBalance()
            }
          } catch (e) {
            console.log('error submitting payload', e)
          }
          payload.onFinish()
          setSubmitting(false)
          props.onClose()
        },
      }}>
      <Content>
        <ModalInput
          name="amount"
          label="CURRENT BALANCE:"
          required
          validation={VALIDATE_LOCATION}
        />
      </Content>
    </Modal>
  )
}
