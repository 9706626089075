import styled from 'styled-components'

import AssistanceNeeded from '../assets/ai-missions/assistance_needed01.png'
import BotRepair1 from '../assets/ai-missions/bot_repair01.png'
import BotRepair2 from '../assets/ai-missions/bot_repair02.png'
import Containment1 from '../assets/ai-missions/containment01.png'
import Containment2 from '../assets/ai-missions/containment02.png'
import Fight1 from '../assets/ai-missions/fight_the_uploaded01.png'
import Fight2 from '../assets/ai-missions/fight_the_uploaded02.png'
import Request1 from '../assets/ai-missions/requesting_backup01.png'
import Request2 from '../assets/ai-missions/requesting_backup02.png'
import Save1 from '../assets/ai-missions/save_the_colonists01.png'
import Save2 from '../assets/ai-missions/save_the_colonists02.png'
import Save3 from '../assets/ai-missions/save_the_colonists03.png'
import Repair from '../assets/ai-missions/starbase_repair01.png'
import Relief1 from '../assets/ai-missions/storm_relief01.png'
import Relief2 from '../assets/ai-missions/storm_relief02.png'
import Relief3 from '../assets/ai-missions/storm_relief03.png'
import Supply1 from '../assets/ai-missions/supply_chain01.png'
import Supply2 from '../assets/ai-missions/supply_chain02.png'
import Supply3 from '../assets/ai-missions/supply_chain03.png'

const MissionImg = styled.img`
  height: 200px;
  object-fit: cover;
  margin-bottom: 25px;
`

export default function MissionImage(props: { mission: any }) {
  const imgs: any = {
    Assistance: [AssistanceNeeded],
    BotRepair: [BotRepair1, BotRepair2],
    Containment: [Containment1, Containment2],
    Fight: [Fight1, Fight2],
    Request: [Request1, Request2],
    Save: [Save1, Save2, Save3],
    Repair: [Repair],
    Relief: [Relief1, Relief2, Relief3],
    Supply: [Supply1, Supply2, Supply3],
  }

  const randomElement = (arr: []) => {
    return arr[Math.floor(Math.random() * arr.length)]
  }

  const selectImage = (mission: any) => {
    console.log({ mission })
    let s = String(mission.title)
    if (s.match(/FIGHT THE UPLOADED/)) {
      return randomElement(imgs.Fight)
    } else if (s.match(/REQUESTING BACKUP/)) {
      return randomElement(imgs.Request)
    } else if (s.match(/CONTAINMENT/)) {
      return randomElement(imgs.Containment)
    } else if (s.match(/ASSISTANCE NEEDED/)) {
      return randomElement(imgs.Assistance)
    } else if (s.match(/STORM RELIEF/)) {
      return randomElement(imgs.Relief)
    } else if (s.match(/SUPPLY CHAIN/)) {
      return randomElement(imgs.Supply)
    } else if (s.match(/SAVE THE COLONISTS/)) {
      return randomElement(imgs.Save)
    } else if (s.match(/BOT REPAIR/)) {
      return randomElement(imgs.BotRepair)
    } else if (s.match(/STARBASE REPAIRS/)) {
      return randomElement(imgs.Repair)
    }
  }

  return (
    <div>
      <MissionImg src={selectImage(props.mission)} />
    </div>
  )
}
