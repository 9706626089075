import { useEthers } from '@usedapp/core'
import styled from 'styled-components'

import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'
import truncateAddress from '../utils/truncateAddress'

export const TITLE_OFFSET = 150

const Title = styled.div`
  border-left: 10px solid ${color('blue')};
  position: absolute;
  top: 100px;
  left: ${TITLE_OFFSET}px;
  padding-left: 20px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: ${toRem(48)};
  line-height: ${toRem(48)};
  text-transform: uppercase;
`

const Account = styled.div`
  font-size: ${toRem(18)};
  text-transform: none;
`

export default function AccountTitle(props: { children?: string }) {
  const { account } = useEthers()
  return (
    <Title>
      <Account>{truncateAddress(account || '', 4)}'s ARMORY</Account>
      {props.children}
    </Title>
  )
}
