import Stack from './Stack'

export default function Connect({ connect }: { connect: () => any }) {
  return (
    <Stack>
      <div>
        <h1>Join the uprising</h1>
        <h2>
          Connect your Metamask <br />
          Wallet to mint a PxlBot.
        </h2>
      </div>
      <div className="row">
        <button className="mint" onClick={connect}>
          Connect
        </button>
      </div>
    </Stack>
  )
}
