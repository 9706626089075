import { DAppProvider, Mainnet, Mumbai } from '@usedapp/core'
import { getDefaultProvider } from 'ethers'
import { BrowserRouter } from 'react-router-dom'

import BetaBanner from './components/BetaBanner'
import Root from './components/Root'
import LoadingProvider from './context/LoadingContext'
import GlobalStyles from './styles/GlobalStyles'
import ThemeProvider from './styles/ThemeProvider'

const mumbaiConfig = {
  autoConnect: true,
  readOnlyChainId: Mumbai.chainId,

  readOnlyUrls: {
    [Mumbai.chainId]:
      'https://polygon-mumbai.infura.io/v3/3501aa525fb74a6ba7823aaeed096e99',
    [Mainnet.chainId]: getDefaultProvider('mainnet'),
  },
  networks: [Mumbai, Mainnet],
}

const localUrl = 'http://127.0.0.1:7546'
const localConfig = {
  autoConnect: true,
  readOnlyChainId: 1337,
  multicallAddresses: { 1337: Mainnet.multicallAddress },
  readOnlyUrls: {
    1337: localUrl,
    [Mainnet.chainId]: getDefaultProvider('mainnet'),
  },
  networks: [
    {
      ...Mainnet,
      chainId: 1337,
      chainName: 'ganache_collectible',
      isLocalChain: true,
      isTestChain: true,
      rpcUrl: localUrl,
    },
    Mainnet,
  ],
}
const isLocal = false

export default function App() {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <LoadingProvider>
        <BetaBanner />
        <DAppProvider config={isLocal ? localConfig : mumbaiConfig}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </DAppProvider>
      </LoadingProvider>
    </ThemeProvider>
  )
}
