import { useState } from 'react'

import { Bot } from '@pxlbots/sdk'
import { FieldValue, Validation } from 'formact'
import styled from 'styled-components'

import ArrowDown from '../assets/arrow-down.svg'
import useJump, { MAX_EDGE, MIN_EDGE } from '../hooks/useJump'
import { formatZeroes } from '../utils/formatLocation'
import getDistance from '../utils/getDistance'
import Modal, { ModalInput } from './Modal'

const Content = styled.div`
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const FormContainer = styled.div`
  width: auto;
`

const FieldContainer = styled.div`
  flex-direction: row;
  width: auto;
  max-width: 500px;
`

const FromField = styled.div`
  align-items: center;
  justify-content: center;
`

const Arrow = styled.img`
  width: 50px;
  margin-top: -40px;
  margin-bottom: 30px;
`

type Form = {
  fromX: string
  fromY: string
  fromZ: string
  toX: string
  toY: string
  toZ: string
}

const VALIDATE_LOCATION: Validation = (location: FieldValue) => {
  if (location) {
    const number = Number(location)

    if (isNaN(number)) {
      return 'Please enter a valid number'
    }

    if (number < MIN_EDGE || number > MAX_EDGE) {
      return `Must be between ${MIN_EDGE} and ${MAX_EDGE}`
    }
  }
}

export default function TravelModal(props: {
  bot: Bot
  onClose: () => any
  open?: boolean
}) {
  const jump = useJump(props.bot)

  const [bases, setBases] = useState(0)

  const fromLocation = props.bot.location || { x: '0', y: '0', z: '0' }

  const toX = formatZeroes(
    Number(fromLocation.x) === MAX_EDGE
      ? (MAX_EDGE - 1).toString()
      : (Number(fromLocation.x) + 1).toString(),
  )

  return (
    <Modal<Form>
      open={props.open}
      onClose={props.onClose}
      title="Jump"
      subtitle="Starbases"
      buttonLabel={`Jump ${bases} ${bases === 1 ? 'starbase' : 'starbases'}`}
      form={{
        onChange: (payload) => {
          setBases(
            getDistance(
              {
                x: payload.values.toX || '0',
                y: payload.values.toY || '0',
                z: payload.values.toZ || '0',
              },
              fromLocation,
            ),
          )
        },
        onSubmit: async (payload) => {
          if (payload.valid) {
            try {
              await jump({
                x: payload.values.toX,
                y: payload.values.toY,
                z: payload.values.toZ,
              })
            } catch (e) {}
            payload.onFinish()
            props.onClose()
          } else {
            payload.onFinish()
          }
        },
        initialValues: {
          fromX: fromLocation.x,
          fromY: fromLocation.y,
          fromZ: fromLocation.z,
          toX,
          toY: formatZeroes(fromLocation.y),
          toZ: formatZeroes(fromLocation.z),
        },
      }}>
      <Content>
        <FormContainer>
          <FieldContainer>
            <FromField>
              <ModalInput
                maxWidth={200}
                name="fromX"
                noBorder
                label="Current Location:"
                required
                disabled
              />
              <Arrow src={ArrowDown} />
            </FromField>
            <FromField>
              <ModalInput
                maxWidth={200}
                name="fromY"
                noBorder
                label=""
                required
                disabled
              />
              <Arrow src={ArrowDown} />
            </FromField>
            <FromField>
              <ModalInput
                maxWidth={200}
                name="fromZ"
                noBorder
                label=""
                required
                disabled
              />
              <Arrow src={ArrowDown} />
            </FromField>
          </FieldContainer>
          <FieldContainer>
            <ModalInput
              maxWidth={200}
              name="toX"
              label="Jump Destination:"
              required
              validation={VALIDATE_LOCATION}
              placeholder="XX"
              maxLength={2}
            />
            <ModalInput
              maxWidth={200}
              name="toY"
              label=""
              required
              validation={VALIDATE_LOCATION}
              placeholder="YY"
              maxLength={2}
            />
            <ModalInput
              maxWidth={200}
              name="toZ"
              label=""
              required
              validation={VALIDATE_LOCATION}
              placeholder="ZZ"
              maxLength={2}
            />
          </FieldContainer>
        </FormContainer>
      </Content>
    </Modal>
  )
}
