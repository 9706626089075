import { useState } from 'react'

import client, { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import ConfirmIcon from '../assets/confirm-icon.svg'
import EditIcon from '../assets/edit-icon.svg'
import { useGame } from '../context/GameContext'
import { useLoading } from '../context/LoadingContext'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'

const Container = styled.div<{ editting?: boolean }>`
  font-size: ${toRem(32)};
  font-family: 'Unica One', sans-serif;
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  ${(props) =>
    props.editting
      ? `
      border: 1px solid ${color('blue')};
      `
      : ''}
`

const Input = styled.input<{ maxWidth?: number }>`
  font-size: ${toRem(32)};
  font-family: 'Unica One', sans-serif;
  text-transform: uppercase;
  background-color: transparent;
  color: white;
  border: none;
  flex: 1;
  box-sizing: border-box;
  max-width: calc(100% - 48px);
  width: auto;
  flex-shrink: 1;
  flex-grow: 1;
`

const BotName = styled.span`
  flex: 1;
`

const IconButton = styled.img`
  cursor: pointer;
  padding: 10px;
`

export default function BotRename(props: { bot: Bot }) {
  const [name, setName] = useState(props.bot.name)
  const [editting, setEditting] = useState(false)
  const { updateBots } = useGame()
  const loading = useLoading()

  const changeName = async () => {
    loading.startLoading('Renaming Bot', true)
    try {
      await client.bots.rename(props.bot.tokenId, name)
      setName(name)
      await updateBots()
    } catch {
      setName(props.bot.name)
    }
    setEditting(false)
    loading.stopLoading()
  }

  return (
    <Container editting={editting}>
      {editting ? (
        <>
          <Input
            onFocus={(e) => e.target.select()}
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value.toUpperCase())}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                changeName()
              }
            }}
          />
          <IconButton src={ConfirmIcon} onClick={changeName} />
        </>
      ) : (
        <>
          <BotName>{name}</BotName>
          <IconButton onClick={() => setEditting(true)} src={EditIcon} />
        </>
      )}
    </Container>
  )
}
