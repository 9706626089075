import { ThemeProvider as StyledProvider } from 'styled-components'

import colors from './colors'

export const THEME = {
  colors,
  spacing: 4,
}

export default function ThemeProvider(props: { children: any }) {
  return <StyledProvider theme={THEME}>{props.children}</StyledProvider>
}
