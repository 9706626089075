import { useLocation } from 'react-router-dom'

import Tab, { Tabs } from '../components/Tab'

const PATHS = [
  { path: '', label: 'Your Bots' },
  { path: '/available-scions', label: 'Available Scions' },
  { path: '/settings', label: 'Account Settings' },
]

export default function TopMenu() {
  const location = useLocation()

  return (
    <Tabs>
      {PATHS.map((p) => {
        const path = `/armory${p.path}`
        return (
          <Tab key={path} to={path} selected={location.pathname === path}>
            {p.label}
          </Tab>
        )
      })}
    </Tabs>
  )
}
