import { useState } from 'react'

import { Bot } from '@pxlbots/sdk'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ArrowBackImg from '../assets/arrow-back.svg'
import color from '../styles/mixins/color'
import BotProfile from './BotProfile'
import { Header, HEADER_HEIGHT } from './GameContainer'

const HeaderItems = styled.div`
  flex-direction: row;
  height: 100%;
`

const ArrowBack = styled.img`
  margin: 20px;
  width: 10px;
`

const Avatar = styled.img<{ width?: number }>`
  height: ${(props) => props.width || HEADER_HEIGHT}px;
  width: ${(props) => props.width || HEADER_HEIGHT}px;
  cursor: pointer;
`

const Info = styled.div`
  border: 1px solid ${color('blue')};
  margin: 40px 80px 0 40px;
  padding: 30px;
  justify-content: space-between;
  background-color: ${color('transparentBlue')};
`

export default function BotPageContainer(props: {
  bot: Bot
  header: React.ReactNode
  children?: React.ReactNode
}) {
  const [profileSelected, setProfileSelected] = useState<boolean>()
  const { bot, children, header } = props

  return (
    <>
      <Header bot={bot}>
        <HeaderItems>
          <Link to="/armory">
            <ArrowBack src={ArrowBackImg} />
          </Link>
          <Avatar
            onClick={() => {
              setProfileSelected(true)
            }}
            src={bot.image || '/images/sample-bot.png'}
          />

          <Info>{header}</Info>
        </HeaderItems>
      </Header>
      <>{children}</>
      <BotProfile
        bot={bot}
        open={profileSelected}
        onClose={() => setProfileSelected(false)}
      />
    </>
  )
}
