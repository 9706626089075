import { Mumbai } from '@usedapp/core'

import CenterContainer from './CenterContainer'

export default function SwitchNetwork() {
  return (
    <CenterContainer>
      <h1>Invalid Network</h1>
      <button
        onClick={async () => {
          try {
            await window.ethereum.send('wallet_switchEthereumChain', [
              { chainId: `0x${Mumbai.chainId.toString(16)}` },
            ])
          } catch (e: any) {
            if (e.code === 4902) {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: `0x${Mumbai.chainId.toString(16)}`,
                    chainName: 'Polygon Testnet',
                    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
                    nativeCurrency: {
                      name: 'MATIC',
                      symbol: 'MATIC',
                      decimals: 18,
                    },
                    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
                  },
                ],
              })
            }
          }
        }}>
        Switch to Polygon
      </button>
    </CenterContainer>
  )
}
