import { useEffect, useMemo, useRef, useState } from 'react'

import styled from 'styled-components'

import useScreenSize from '../hooks/useScreenSize'

const EXTRA_ITEMS = 4

const Container = styled.div<{
  scrollable?: boolean
  height: number | string
  maxWidth?: number
  offset?: number
}>`
  display: block;
  width: 100%;
  max-width: ${(props) => props.maxWidth}px;
  overflow: hidden;
  position: relative;
  height: ${(props) =>
    typeof props.height === 'string' ? props.height : `${props.height}px`};
  padding-left: ${(props) => props.offset}px;
`

const DraggingPanel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: 0;
  transition: background-color 0.5s;
`

const ItemsContainer = styled.div<{ initialPosition: number }>`
  left: ${(props) => props.initialPosition}px;
  position: relative;
  height: 100%;
  z-index: 1;
`

const Item = styled.div<{
  width: number
  index: number
}>`
  position: absolute;
  width: ${(props) => props.width}px;
  left: ${(props) => props.index * props.width}px;
  height: 100%;
`

type Props = {
  children: any[]
  itemWidth: number
  itemHeight: number | string
  maxWidth?: number
  offset?: number
}

function ScrollableCarousel({
  children,
  itemWidth,
  itemHeight,
  maxVisibleItems,
}: Props & { maxVisibleItems: number }) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const itemsRef = useRef<HTMLDivElement | null>(null)
  const panelRef = useRef<HTMLDivElement | null>(null)

  const [seed, setSeed] = useState(-EXTRA_ITEMS)

  const visibleChildren = useMemo(
    () => Array.from(Array(maxVisibleItems + EXTRA_ITEMS * 2).keys()),
    [maxVisibleItems],
  )

  useEffect(() => {
    if (containerRef.current && itemsRef.current) {
      const container = containerRef.current
      const items = itemsRef.current

      setSeed(-EXTRA_ITEMS)

      items.style.left = `${EXTRA_ITEMS}px`
      items.style.transition = 'left 1s'
      container.style.cursor = 'grab'

      const timeoutID = setTimeout(() => {
        items.style.transition = 'none'
      }, 1000)

      const pos = {
        current: 0,
        mouseDown: 0,
        initalPosition: 0,
        lastPosition: 0,
        seed: 0,
        dragging: null as 'left' | 'right' | null,
      }

      const moveListener = (position: number) => {
        if (!pos.dragging) return
        if (pos.lastPosition !== position) {
          const newDirection = pos.lastPosition < position ? 'right' : 'left'
          if (newDirection !== pos.dragging) {
            pos.dragging = newDirection
          }
        }
        pos.lastPosition = position

        const progress = position - pos.seed * itemWidth
        if (pos.dragging === 'right') {
          if (progress > itemWidth) {
            pos.seed++
            setSeed((s) => s - 1)
          }
        } else {
          if (progress < 0) {
            pos.seed--
            setSeed((s) => s + 1)
          }
        }
      }

      const getCurrentPosition = () =>
        Number(items.style.left.replace('px', '') || 0)

      const mouseDownHandler = function (e: any) {
        pos.dragging = 'right'
        container.style.cursor = 'grabbing'
        container.style.userSelect = 'none'

        pos.initalPosition = getCurrentPosition()
        pos.mouseDown = e.clientX

        container.addEventListener('mousemove', mouseMoveHandler)
        container.addEventListener('mouseup', mouseUpHandler)
      }

      const mouseMoveHandler = function (e: any) {
        panelRef.current!.style.zIndex = '1'
        panelRef.current!.style.backgroundColor = 'rgba(0, 0, 0, 0.2)'
        const offset = e.clientX - pos.mouseDown
        const position = pos.initalPosition + offset
        items.style.left = `${position}px`
        moveListener(position)
      }

      const mouseUpHandler = function () {
        panelRef.current!.style.zIndex = '0'
        panelRef.current!.style.backgroundColor = 'transparent'
        container.style.cursor = 'grab'
        container.style.removeProperty('user-select')
        pos.dragging = null

        container.removeEventListener('mousemove', mouseMoveHandler)
        container.removeEventListener('mouseup', mouseUpHandler)
      }

      // Attach the handler
      container.addEventListener('mousedown', mouseDownHandler)

      return () => {
        container.removeEventListener('mousedown', mouseDownHandler)
        clearTimeout(timeoutID)
      }
    }
  }, [containerRef.current, itemsRef.current, itemWidth, maxVisibleItems])

  return (
    <Container scrollable ref={containerRef} height={itemHeight}>
      <ItemsContainer
        ref={itemsRef}
        initialPosition={(itemWidth * EXTRA_ITEMS) / 2}>
        {visibleChildren.map((_, index) => {
          const itemIndex = index + seed

          let childIndex =
            itemIndex >= 0
              ? itemIndex % children.length
              : children.length + (itemIndex % children.length)

          if (childIndex === children.length) {
            childIndex = 0
          }

          return (
            <Item
              index={itemIndex}
              width={itemWidth}
              key={`item-${itemIndex}-${childIndex}`}>
              {children[childIndex]}
            </Item>
          )
        })}
      </ItemsContainer>
      <DraggingPanel ref={panelRef} />
    </Container>
  )
}

export default function Carousel(props: Props) {
  const { children, itemWidth, itemHeight, maxWidth, offset = 0 } = props

  const screenSize = useScreenSize(maxWidth)

  // Still getting screen size
  if (!screenSize) {
    return null
  }

  // If there's a need to scroll render the ScrollableCarousel
  if (screenSize - offset < children.length * itemWidth) {
    const itemsToDisplay = Math.ceil(screenSize / itemWidth)
    return <ScrollableCarousel {...props} maxVisibleItems={itemsToDisplay} />
  }

  // Just render the items
  return (
    <Container height={itemHeight} maxWidth={maxWidth} offset={offset}>
      <ItemsContainer initialPosition={0}>
        {children.map((item, index) => {
          return (
            <Item index={index} width={itemWidth} key={`item-${index}`}>
              {item}
            </Item>
          )
        })}
      </ItemsContainer>
    </Container>
  )
}
