import styled from 'styled-components'

import AccountTitle from '../components/AccountTitle'
import CenterContainer from '../components/CenterContainer'
import { Header, HEADER_HEIGHT } from '../components/GameContainer'
import TopMenu from '../components/TopMenu'

export const PageContainer = styled(CenterContainer)`
  top: ${HEADER_HEIGHT}px;
`

export default function AccountSettings() {
  return (
    <>
      <Header>
        <AccountTitle>Account Settings</AccountTitle>
        <TopMenu />
      </Header>
      <PageContainer>Coming Soon</PageContainer>
    </>
  )
}
