import { memo, useEffect, useMemo, useState } from 'react'

import MANIFEST from '@pxlbots/sdk/dist/manifests/80001.json'
import {
  useCall,
  useCalls,
  useContractFunction,
  useEthers,
} from '@usedapp/core'
import { Network, Alchemy } from 'alchemy-sdk'
import { BigNumber, Contract } from 'ethers'
import { useNavigate } from 'react-router-dom'

import AccountTitle, { TITLE_OFFSET } from '../components/AccountTitle'
import Carousel from '../components/Carousel'
import { Header } from '../components/GameContainer'
import TopMenu from '../components/TopMenu'
import { useLoading } from '../context/LoadingContext'
import PxlbotCollectible from '../utils/abis/PxlbotCollectible.json'
import { PageContainer } from './AccountSettings'
import {
  BotContainer,
  BotItem,
  BotItemDetails,
  BotItemName,
  BOT_CONTAINER_HEIGHT,
  BOT_CONTAINER_PADDING,
  BOT_CONTAINER_WIDTH,
} from './Bots'

// Optional Config object, but defaults to demo api-key and eth-mainnet.
const settings = {
  apiKey: 'PCq0pe6wvSUMEKuudsVCgbWQnhfRov5h', // Replace with your Alchemy API Key.
  network: Network.ETH_MAINNET, // Replace with your network.
}

const alchemy = new Alchemy(settings)

const CollectiblesContract = new Contract(
  PxlbotCollectible.address,
  PxlbotCollectible.abi,
)

type NFTData = {
  name: string
  description: string
  image: string
  external_url: string
  attributes: {
    trait_type: string
    value: string
  }[]
}

const AlphaMinterContract = new Contract(
  MANIFEST.AlphaMinter.address,
  MANIFEST.AlphaMinter.abi,
)

const loadingState = ['Success', 'Mining', 'PendingSignature']

function AvailableBotItem({ id }: { id: number }) {
  const loading = useLoading()
  const [bot, setBot] = useState<NFTData | null>(null)
  const transaction = useContractFunction(
    AlphaMinterContract,
    'claimFromCollectible',
  )
  const isLoading = loadingState.includes(transaction.state.status)

  const navigate = useNavigate()

  useEffect(() => {
    if (transaction.state.status === 'Success') {
      navigate('/armory')
    }
  }, [transaction.state.status])

  useEffect(() => {
    if (isLoading) {
      loading.startLoading('Claiming Scion')
      return
    }
    loading.stopLoading()
  }, [isLoading])

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await alchemy.nft.getNftMetadata(
          PxlbotCollectible.address,
          id,
        )
        setBot(response.rawMetadata)
      } catch {
        setBot(null)
      }
    }
    fetchAttributes()
  }, [id])

  if (!bot) {
    return null
  }

  return (
    <BotContainer>
      <BotItem
        onMouseUp={(e) => {
          e.preventDefault()
        }}
        url={bot.external_url}>
        <BotItemName>{bot.name}-001</BotItemName>
        <BotItemDetails>
          Faction:{' '}
          {bot.attributes.find((a) => a.trait_type === 'faction')?.value ||
            null}
        </BotItemDetails>
        <BotItemDetails>
          Class:{' '}
          {bot.attributes.find((a) => a.trait_type === 'bot_class')?.value ||
            null}
        </BotItemDetails>
        <button
          style={{ maxWidth: 150, minWidth: 150 }}
          className="wallet mt"
          onMouseUp={async (e) => {
            e.stopPropagation()
            try {
              transaction.send(id)
            } catch {}
          }}>
          Claim
        </button>
      </BotItem>
    </BotContainer>
  )
}

const MemoizedBot = memo(AvailableBotItem)

function Bots({ bots }: { bots: number[] }) {
  const [loaded, setLoaded] = useState(false)
  const loading = useLoading()

  const calls = useMemo(() => {
    return bots.map((id) => {
      return {
        contract: AlphaMinterContract,
        method: 'claimed_playable_tokens',
        args: [id],
      }
    })
  }, [bots.join(',')])

  const botsCalls = useCalls(calls)

  const result = useMemo(() => {
    return botsCalls
      .map((c, index) => {
        if (!c?.value) {
          return {
            id: 0,
            redemed: true,
          }
        }
        return {
          id: bots[index],
          redemed: !!c?.value[0],
        }
      })
      .filter((b) => !b.redemed)
  }, [JSON.stringify(botsCalls)])

  useEffect(() => {
    if (loaded) return
    if (result.length) {
      loading.stopLoading()

      setLoaded(true)
    }
  }, [result.length])

  const claimableBots = result.filter((bot) => !bot.redemed)

  if (!claimableBots.length)
    return <PageContainer>No Pxlbots available</PageContainer>

  return (
    <Carousel
      itemWidth={BOT_CONTAINER_WIDTH + BOT_CONTAINER_PADDING}
      itemHeight={BOT_CONTAINER_HEIGHT}
      offset={TITLE_OFFSET - BOT_CONTAINER_PADDING}>
      {result.map((bot) => (
        <MemoizedBot key={`bot-${bot.id}`} id={bot.id} />
      ))}
    </Carousel>
  )
}

const MemoizedBots = memo(Bots)

export default function AvailableScions() {
  const [loaded, setLoaded] = useState(false)
  const { account } = useEthers()
  const response = useCall(
    {
      contract: CollectiblesContract,
      method: 'tokensOfOwner',
      args: [account],
    },
    { chainId: 1 },
  )

  const loading = useLoading()

  useEffect(() => {
    if (loaded) return
    if (!response || !response.value) {
      loading.startLoading('LOADING AVAILABLE SCIONS...')
      return
    }

    setLoaded(true)
    loading.stopLoading()
  }, [response, loaded])

  const bots = useMemo(() => {
    return (response?.value[0].map((i: BigNumber) => i.toNumber()) ||
      []) as number[]
  }, [JSON.stringify(response?.value)])

  return (
    <>
      <Header>
        <AccountTitle>Select your Pxlbot</AccountTitle>
        <TopMenu />
      </Header>
      {bots.length ? (
        <MemoizedBots bots={bots} />
      ) : (
        <PageContainer>No Pxlbots available</PageContainer>
      )}
    </>
  )
}
