import styled from 'styled-components'

export const BANNER_HEIGHT = 57

const Container = styled.div`
  background-color: #316074;
  height: ${BANNER_HEIGHT}px;
  align-items: center;
  justify-content: center;
`

export default function BetaBanner() {
  return (
    <Container>
      <p>
        PXLBOTS: ENTER THE PXLVERSE IS IN BETA! SUBMIT GAME BUGS & FEEDBACK{' '}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScSYcZo5aftrs_EjNd2gf_UfwR6-G2xiBInZBptq23OknXJqw/viewform"
          target="_new">
          HERE
        </a>
      </p>
    </Container>
  )
}
