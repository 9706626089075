import { useEffect, useState } from 'react'

import Form, {
  FieldProps,
  FormProps,
  FormValues,
  useField,
  useForm,
} from 'formact'
import styled, { keyframes } from 'styled-components'

import { useLoading } from '../context/LoadingContext'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'

const expand = keyframes`
  from { width: 0%; height: 0%; border-radius: 100%; }
  to { width: 100%; height: 100%; border-radius: 0%; }
`
const shrink = keyframes`
  from { width: 100%; height: 100%;  border-radius: 0%;}
  to { width: 0%; height: 0%;  border-radius: 100%; }
`
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`

const AnimationContainer = styled.div<{ open?: boolean }>`
  position: absolute;
  overflow: hidden;
  animation: ${(props) => (props.open ? expand : shrink)} 0.3s normal forwards;
`

const Backdrop = styled.div<{ visible?: boolean }>`
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: ${color('deepblue')};
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.visible ? 0.85 : 0)};
  transition: opacity 0.3s ease-in-out;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 40px,
    ${color('darkblue')} 40px,
    ${color('darkblue')} 80px
  );
`

const ContentContainer = styled.div`
  top: 75px;
  left: 75px;
  width: calc(100vw - 150px);
  height: calc(100vh - 150px);
  align-items: center;
  justify-content: center;
  position: absolute;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${color('deepblue')};
  padding: 40px;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const Title = styled.div`
  font-size: ${toRem(170)};
  line-height: ${toRem(170)};
  text-transform: uppercase;
  margin-bottom: ${toRem(40)};
  margin-right: ${toRem(40)};
  width: auto;
  font-family: 'Unica One', sans-serif;
  font-weight: bold;
`
const Subtitle = styled.div`
  font-size: ${toRem(60)};
  line-height: ${toRem(100)};
  text-transform: uppercase;
  font-family: 'Unica One', sans-serif;
  overflow-y: auto;
`

const ContentChildren = styled.div`
  flex: 1;
  margin-bottom: ${toRem(40)};
`

const ButtonContainer = styled.div`
  max-width: 400px;
`

function ModalButton(props: { label: string; disabled?: boolean }) {
  const form = useForm()
  const loader = useLoading()
  const disabled = !form.valid || form.submitting || props.disabled
  return (
    <ButtonContainer>
      <button
        disabled={disabled}
        className={['wallet', disabled ? 'disabled' : ''].join(' ')}
        onClick={() => form.submit()}>
        {form.submitting
          ? loader.loading?.message || 'SUBMITTING'
          : props.label}
      </button>
    </ButtonContainer>
  )
}

export default function Modal<T extends FormValues>(props: {
  open?: boolean
  children: React.ReactNode
  onClose?: () => void
  title?: string
  subtitle?: string
  disableButton?: boolean
  buttonLabel?: string
  form?: Omit<FormProps<T>, 'children'>
}) {
  const [animate, setAnimate] = useState<boolean | undefined>(false)
  const [show, setShow] = useState<boolean | undefined>(false)

  useEffect(() => {
    setAnimate(props.open)
    if (!props.open) {
      setTimeout(() => {
        setShow(false)
      }, 300)
    } else {
      setShow(true)

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          props.onClose?.()
        }
      }
      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [props.open])

  if (!show) return null
  return (
    <Form<T> {...(props.form || {})}>
      <Container>
        <Backdrop onClick={props.onClose} visible={animate}>
          <Background className="current" />
        </Backdrop>
        <ContentContainer>
          <AnimationContainer open={animate}>
            <Content>
              {props.title ? (
                <TitleContainer>
                  <Title>{props.title}</Title>
                  <Subtitle>{props.subtitle}</Subtitle>
                </TitleContainer>
              ) : null}
              <ContentChildren>{props.children}</ContentChildren>

              {props.buttonLabel ? (
                <ModalButton
                  disabled={props.disableButton}
                  label={props.buttonLabel}
                />
              ) : null}
            </Content>
          </AnimationContainer>
        </ContentContainer>
      </Container>
    </Form>
  )
}

export function useToggleModal(initialOpen: boolean = false) {
  const [open, setOpen] = useState(initialOpen)

  const toggle = () => {
    setOpen(!open)
  }

  return {
    open,
    toggle,
  }
}

const InputContainer = styled.div`
  height: 200px;
  position: relative;
`
const InputBorderContainer = styled.div`
  position: relative;
`

const Label = styled.label`
  height: 30px;
  text-transform: uppercase;
`

const Input = styled.input<{ maxWidth?: number }>`
  width: auto;
  background-color: transparent;
  border: none;
  color: white;
  font-size: ${toRem(75)};
  padding: 10px;
  text-align: right;
  outline: none;
  font-family: 'Unica One', sans-serif;
  text-transform: uppercase;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
`

const Error = styled.div`
  padding-top: 10px;
  color: ${color('yellow')};
  height: 30px;
`

const BorderTopLeft = styled.div`
  width: 30px;
  height: 30px;
  border-top: 4px solid ${color('blue')};
  border-left: 4px solid ${color('blue')};
  position: absolute;
  top: 0;
  left: 0;
`
const BorderBottomLeft = styled.div`
  width: 30px;
  height: 30px;
  border-bottom: 4px solid ${color('blue')};
  border-left: 4px solid ${color('blue')};
  position: absolute;
  bottom: 0;
  left: 0;
`
const BorderTopRight = styled.div`
  width: 30px;
  height: 30px;
  border-top: 4px solid ${color('blue')};
  border-right: 4px solid ${color('blue')};
  position: absolute;
  top: 0;
  right: 0;
`
const BorderBottomRight = styled.div`
  width: 30px;
  height: 30px;
  border-bottom: 4px solid ${color('blue')};
  border-right: 4px solid ${color('blue')};
  position: absolute;
  bottom: 0;
  right: 0;
`

export function ModalInput(
  props: FieldProps & {
    label?: string
    disabled?: boolean
    noBorder?: boolean
    placeholder?: string
    maxLength?: number
    maxWidth?: number
  },
) {
  const field = useField<string>(props)
  return (
    <InputContainer>
      <Label htmlFor={props.name}>{props.label}</Label>
      <InputBorderContainer>
        {!props.noBorder ? (
          <>
            <BorderTopLeft />
            <BorderBottomLeft />
            <BorderTopRight />
            <BorderBottomRight />
          </>
        ) : null}

        <Input
          onBlur={() => field.onBlur()}
          disabled={props.disabled}
          name={props.name}
          onFocus={(e) => {
            e.target.select()
          }}
          value={field.fieldValue || ''}
          onChange={(e) => field.update(e.target.value.toUpperCase())}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          maxWidth={props.maxWidth}
        />
      </InputBorderContainer>

      <Error>{field.showError ? field.errorMessage : null}</Error>
    </InputContainer>
  )
}
