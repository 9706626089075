import { useEffect, useState } from 'react'

import { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import { useGame } from '../context/GameContext'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'
import getDistance from '../utils/getDistance'
import BotPageContainer from './BotPageContainer'
import TravelBackground from './TravelBackground'
import TravelLoader from './TravelLoader'

const Subtitle = styled.div`
  color: ${color('blue')};
  text-transform: uppercase;
`

const Title = styled.div`
  font-size: ${toRem(48)};
  line-height: ${toRem(48)};
  text-transform: uppercase;
`

const OptionsContainer = styled.div`
  flex-direction: row;
  height: 85px;
  line-height: ${toRem(24)};
`

const getETA = (eta = 0) => {
  return Math.max((eta - Date.now() / 1000) / 60 / 60, 0)
}

export const useBotIntransitState = (bot: Bot) => {
  const { missions } = bot
  const mission = missions[0]
  const { updateBots } = useGame()

  const [hours, setHours] = useState(getETA(mission?.eta))
  useEffect(() => {
    const id = setInterval(() => {
      const hours = getETA(mission?.eta)
      setHours(hours)
      if (hours <= 0) {
        updateBots()
      }
    }, 10000)

    return () => clearInterval(id)
  }, [])

  const destination = bot.travelInfo?.metadata.destination
  const bases = getDistance(destination, bot.location)

  const totalHours =
    ((mission?.eta || 0) - (bot.travelInfo?.createdAt || 0) / 1000) / 60 / 60
  const progress = Math.round(((totalHours - hours) / totalHours) * bases)

  return { destination, bases, totalHours, progress, hours }
}

export default function BotIntransit({ bot }: { bot: Bot }) {
  const { destination, bases, hours, progress } = useBotIntransitState(bot)
  return (
    <>
      <TravelBackground progress={progress} />
      <BotPageContainer
        bot={bot}
        header={
          <>
            <Subtitle>
              {bot.name} // Status: {progress} of {bases} Starbases (
              {Math.round((progress / bases) * 100)}% complete)
            </Subtitle>
            <Title>In Transit</Title>
            <OptionsContainer>
              <TravelLoader
                from={bot.location}
                to={destination}
                eta={hours}
                total={bases}
                traveled={progress}
              />
            </OptionsContainer>
          </>
        }
      />
    </>
  )
}
