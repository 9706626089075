import { useRef } from 'react'

import MetaMaskOnboarding from '@metamask/onboarding'

import CenterContainer from './CenterContainer'

export default function NoMetamask() {
  const onboarding = useRef<MetaMaskOnboarding>(new MetaMaskOnboarding())

  return (
    <CenterContainer>
      <h1>Almost there!</h1>
      <p>
        In order to explore this dApp you need to install the Metamask extension
        for your browser and connect to the Ethereum Blockchain
      </p>
      <br />
      <br />
      <button onClick={() => onboarding.current?.startOnboarding()}>
        Get Metamask
      </button>
    </CenterContainer>
  )
}
