import { useEthers } from '@usedapp/core'

import Router from '../Router'
import NoMetamask from './NoMetamask'
import SwitchNetwork from './SwitchNetwork'

export default function Root() {
  const ethers = useEthers()

  return window?.ethereum ? (
    ethers.chainId && ethers.chainId !== 1 ? (
      <Router />
    ) : (
      <SwitchNetwork />
    )
  ) : (
    <NoMetamask />
  )
}
