import client from '@pxlbots/sdk'
import { useEthers } from '@usedapp/core'

import Connect from '../components/Connect'
import Connection from '../components/Connection'
import Mint from '../components/Mint'
import PageLayout from '../components/PageLayout'

export default function MintPage() {
  const { account, chainId } = useEthers()

  return (
    <PageLayout>
      {!account ? (
        <Connect connect={client.account.connect} />
      ) : !chainId ? (
        <h1>Network not supported</h1>
      ) : (
        <>
          <Mint account={account} />
          <Connection account={account} />
        </>
      )}
    </PageLayout>
  )
}
