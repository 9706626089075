import { useEffect, useState } from 'react'

import client from '@pxlbots/sdk'
import styled from 'styled-components'

import spacing from '../styles/mixins/spacing'
import truncateAddress from '../utils/truncateAddress'
import Spacer from './Spacer'

const Container = styled.div`
  width: auto;
  position: absolute;
  right: ${spacing(20)};
  bottom: ${spacing(20)};
  text-align: right;

  @media (max-width: 1200px) {
    position: relative;
    right: initial;
    bottom: initial;
  }
`

export default function Connection({ account }: { account?: string }) {
  const [address, setAddress] = useState('')
  useEffect(() => {
    const getAddress = async () => {
      try {
        setAddress((await client.getContract('Pxlbot')).address)
      } catch (e) {}
    }

    getAddress()
  }, [])

  return (
    <Container>
      <Spacer v={20} />
      <h3>Connected to PxlBots</h3>
      <p>{truncateAddress(address)}</p>

      {account ? (
        <>
          <Spacer v={8} />
          <h3>Your Account</h3>
          <p>{truncateAddress(account)}</p>
        </>
      ) : null}
    </Container>
  )
}
