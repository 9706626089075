import { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import { HEADER_HEIGHT } from '../components/GameContainer'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'
import BotRename from './BotRename'
import Modal from './Modal'

const Avatar = styled.img<{ width?: number }>`
  height: ${(props) => props.width || HEADER_HEIGHT}px;
  width: ${(props) => props.width || HEADER_HEIGHT}px;
`

const ProfileItems = styled.div`
  flex-direction: row;
  > div {
    border: 1px solid ${color('blue')};
  }
`

const ProfileItem = styled.div`
  height: auto;
  width: auto;
`

const ProfileItemContainer = styled(ProfileItem)`
  padding: 20px;
  min-width: 35%;
`

const ItemRow = styled.div`
  margin-bottom: 32px;
`

const ItemLabel = styled.h2`
  margin-top: 0;
  font-size: ${toRem(14)};
  line-height: ${toRem(14)};
  color: ${color('blue')};
  font-weight: normal;
`
const ItemValue = styled.p`
  font-size: ${toRem(32)};
  font-family: 'Unica One', sans-serif;
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
`

const AttributeTitle = styled(ItemLabel)`
  color: ${color('white')};
  margin-top: 30px;
  margin-bottom: 40px;
`

const AttributeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
  margin-bottom: 20px;
`
const AttributeItem = styled.div`
  width: auto;
`
const AttributeLabel = styled(AttributeItem)`
  font-size: ${toRem(14)};
  color: ${color('blue')};
  font-weight: normal;
  width: 40px;
`
const AttributeGauge = styled(AttributeItem)`
  align-self: center;
  width: 80%;
  height: 8px;
  background-color: #1b6276;
`
const AttributeBar = styled.div`
  width: 60%;
  height: 100%;
  background-color: ${color('blue')};
`

interface AttributeGaugeProps {
  val?: number
}

const AttrMobility = styled(AttributeBar)<AttributeGaugeProps>`
  width: ${(props) => props.val || 0}%;
`

const AttributeValue = styled(AttributeLabel)``

export default function BotProfile(props: {
  bot: Bot
  open?: boolean
  onClose: () => any
}) {
  return (
    <>
      <Modal open={props.open} onClose={props.onClose}>
        <ProfileItems>
          <ProfileItem>
            <Avatar src={props.bot.image} />
          </ProfileItem>
          <ProfileItemContainer>
            <ItemRow>
              <ItemLabel>NAME</ItemLabel>
              <BotRename bot={props.bot} />
            </ItemRow>
            <ItemRow>
              <ItemLabel>FACTION</ItemLabel>
              <ItemValue>{props.bot.faction}</ItemValue>
            </ItemRow>
          </ProfileItemContainer>
          <ProfileItemContainer>
            <AttributeTitle>Attributes</AttributeTitle>
            <AttributeRow>
              <AttributeLabel>MOB</AttributeLabel>
              <AttributeGauge>
                <AttrMobility val={props.bot.attributes?.mobility}>
                  &nbsp;
                </AttrMobility>
              </AttributeGauge>
              <AttributeValue>{props.bot.attributes?.mobility}</AttributeValue>
            </AttributeRow>
            <AttributeRow>
              <AttributeLabel>DUR</AttributeLabel>
              <AttributeGauge>
                <AttrMobility val={props.bot.attributes?.durability}>
                  &nbsp;
                </AttrMobility>
              </AttributeGauge>
              <AttributeValue>
                {props.bot.attributes?.durability}
              </AttributeValue>
            </AttributeRow>
            <AttributeRow>
              <AttributeLabel>INT</AttributeLabel>
              <AttributeGauge>
                <AttrMobility val={props.bot.attributes?.intelligence}>
                  &nbsp;
                </AttrMobility>
              </AttributeGauge>
              <AttributeValue>
                {props.bot.attributes?.intelligence}
              </AttributeValue>
            </AttributeRow>
            <AttributeRow>
              <AttributeLabel>STE</AttributeLabel>
              <AttributeGauge>
                <AttrMobility val={props.bot.attributes?.stealth}>
                  &nbsp;
                </AttrMobility>
              </AttributeGauge>
              <AttributeValue>{props.bot.attributes?.stealth}</AttributeValue>
            </AttributeRow>
            <AttributeRow>
              <AttributeLabel>COM</AttributeLabel>
              <AttributeGauge>
                <AttrMobility val={props.bot.attributes?.communication}>
                  &nbsp;
                </AttrMobility>
              </AttributeGauge>
              <AttributeValue>
                {props.bot.attributes?.communication}
              </AttributeValue>
            </AttributeRow>
          </ProfileItemContainer>
        </ProfileItems>
      </Modal>
    </>
  )
}
