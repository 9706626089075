import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import BotIdle from '../components/BotIdle'
import BotIntransit from '../components/BotIntransit'
import BotMissionComplete from '../components/BotMissionComplete'
import useBot from '../hooks/useBot'

export default function BotPage() {
  const { id } = useParams()

  const bot = useBot(id || 0)
  const navigate = useNavigate()

  useEffect(() => {
    if (!bot) {
      navigate('/armory')
    }
  }, [bot])

  if (!bot) {
    return null
  }

  switch (bot.status) {
    case 'in-transit':
      return <BotIntransit bot={bot} />
    case 'mission-complete':
      return <BotMissionComplete bot={bot} />
    default:
      return <BotIdle bot={bot} />
  }
}
