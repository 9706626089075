import { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import color from '../styles/mixins/color'

const AttributeRow = styled.h4`
  color: ${color('grey')};
  font-size: 0.85em;
  margin-top: 15px;
`

export default function BotAttrs(props: { bot: Bot }) {
  return (
    <div>
      <AttributeRow>Mobility: {props.bot.attributes?.mobility}</AttributeRow>
      <AttributeRow>
        Durability: {props.bot.attributes?.durability}
      </AttributeRow>
      <AttributeRow>
        Intelligence: {props.bot.attributes?.intelligence}
      </AttributeRow>
      <AttributeRow>Stealth: {props.bot.attributes?.stealth}</AttributeRow>
      <AttributeRow>
        Communication: {props.bot.attributes?.communication}
      </AttributeRow>
    </div>
  )
}
