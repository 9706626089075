import { BotLocation } from '@pxlbots/sdk'

export default function getDistance(
  from: BotLocation = { x: '0', y: '0', z: '0' },
  to: BotLocation = { x: '0', y: '0', z: '0' },
): number {
  const distanceX = Math.abs(Number(to.x || '0') - Number(from.x || '0'))
  const distanceY = Math.abs(Number(to.y || '0') - Number(from.y || '0'))
  const distanceZ = Math.abs(Number(to.z || '0') - Number(from.z || '0'))

  return distanceX + distanceY + distanceZ
}
