import styled from 'styled-components'

import color from '../styles/mixins/color'

const CenterContainer = styled.div<{ bgColor?: string }>`
  background: ${(props) => props.bgColor || color('deepblue')};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 40px;
  text-align: center;
`

export default CenterContainer
