import { createContext, useContext, useEffect, useState } from 'react'

import client, { Bot } from '@pxlbots/sdk'
import { signIn } from '@pxlbots/sdk/dist/utils/firebase/auth'
import { useEthers } from '@usedapp/core'
import { orderBy } from 'lodash'

import CenterContainer from '../components/CenterContainer'
import { useLoading } from './LoadingContext'

const LOADING_MESSAGE = 'LOADING ARMORY...'

const Context = createContext<{
  account: string
  bots: Bot[]
  balance: number
  loading: boolean
  refresh: () => Promise<void>
  updateBots: () => Promise<void>
  updateBalance: () => Promise<void>
}>({
  account: '',
  bots: [],
  balance: 0,
  loading: false,
  refresh: async () => {},
  updateBots: async () => {},
  updateBalance: async () => {},
})

export function useGame() {
  return useContext(Context)
}

export default function GameProvider({ children }: { children: any }) {
  const { startLoading, stopLoading, loading } = useLoading()
  const { account, chainId } = useEthers()
  const [bots, setBots] = useState<Bot[] | null>(null)
  const [balance, setBalance] = useState<number>(0)

  const fetchBots = async () => {
    const bots = await client.bots.fetchByOwner(account || '')
    const sorted = orderBy(bots, 'tokenId', 'asc')
    setBots(sorted)
  }

  const fetchBalance = async () => {
    const balance = (await client.pxl.balanceOf(account || '')) / 10 ** 18
    setBalance(balance)
  }

  const updateData = async () => {
    const id = startLoading(LOADING_MESSAGE, true, 3)
    await client.account.connect(true)
    try {
      await fetchBots()
    } catch (e) {
      //  TODO: handle error
      // eslint-disable-next-line no-console
      console.error(e)
    }
    try {
      await fetchBalance()
    } catch (e) {
      //  TODO: handle error
      // eslint-disable-next-line no-console
      console.error(e)
    }
    stopLoading(id)
  }

  useEffect(() => {
    signIn()
    setBots(null)
    setBalance(0)

    if (!account || '') {
      return
    }

    updateData()
  }, [account, chainId])

  if (!account || !bots) {
    if (loading?.message === LOADING_MESSAGE) {
      return <CenterContainer />
    }
    return (
      <CenterContainer>
        <h2>There was an error retrieving your data</h2>
      </CenterContainer>
    )
  }

  return (
    <Context.Provider
      value={{
        account,
        bots,
        balance,
        loading: loading?.message === LOADING_MESSAGE,
        refresh: updateData,
        updateBots: fetchBots,
        updateBalance: fetchBalance,
      }}>
      {children}
    </Context.Provider>
  )
}
