import { Route, Routes } from 'react-router-dom'

import Armory from './pages/Armory'
import Index from './pages/Index'
import Mint from './pages/Mint'

export default function Router() {
  return (
    <Routes>
      <Route index element={<Index />} />
      <Route path="/armory/*" element={<Armory />} />
      <Route path="/mint" element={<Mint />} />
    </Routes>
  )
}
