import { useEffect, useMemo, useState } from 'react'

import { BotLocation } from '@pxlbots/sdk'
import styled, { keyframes } from 'styled-components'

import CoordinateImage from '../assets/coordinate-travel.svg'
import color from '../styles/mixins/color'
import formatLocation from '../utils/formatLocation'

const MAX_ACTIVE = 50

const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`

const pulse = keyframes`
 100% { background-color: ${color('deepblue')} }
 50% {background-color: ${color('blue')}}
 0% { background-color: white; }
 `

const ItemsContainer = styled.div`
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
`

const ANIMATION_DURATION = 1500

const LoaderItem = styled.div<{ alive?: boolean; total: number }>`
  height: 42px;
  width: calc(${({ total }) => 100 / total}% - 10px);
  background-color: ${({ theme }) => theme.colors.darkblue};
  transform: skew(-20deg);
  animation-name: ${({ alive }) => (alive ? pulse : 'none')};
  animation-duration: ${ANIMATION_DURATION}ms;
  animation-iteration-count: infinite;
  margin-right: 10px;
`

function AnimatedItem({
  alive,
  index,
  total,
}: {
  alive?: boolean
  index: number
  total: number
}) {
  const [localAlive, setAlive] = useState<boolean | undefined>(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlive(alive)
    }, (index * ANIMATION_DURATION) / total)
    return () => clearTimeout(timeout)
  }, [alive, index])

  return <LoaderItem alive={localAlive} total={total} />
}

const Coordinates = styled.div`
  background: url(${CoordinateImage}) no-repeat center top;
  height: 90px;
  width: 130px;
  text-align: center;
  font-size: 16px;
  color: ${color('blue')};
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding-top: 60px;
`

const ETA = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  align-items: center;
  color: ${color('blue')};
`

type Props = {
  from?: BotLocation
  to?: BotLocation
  eta: number
  traveled: number
  total: number
}

export default function TravelLoader(props: Props) {
  const total = Math.min(MAX_ACTIVE, props.total)
  const alive = Math.max(total - (props.total - props.traveled), 1)

  const items = useMemo(() => {
    return Array.from({ length: total }, (_, i) => (
      <AnimatedItem
        key={`${alive}-${i}`}
        index={i}
        alive={i < alive}
        total={total}
      />
    ))
  }, [alive, total])

  return (
    <Container>
      <Coordinates>{formatLocation(props.from)}</Coordinates>
      <ItemsContainer>{items}</ItemsContainer>
      <Coordinates>{formatLocation(props.to)}</Coordinates>
      <ETA>
        {' '}
        {props.eta > 0.01
          ? `ETA // ${props.eta.toFixed(2)} T-HS`
          : 'Arriving...'}
      </ETA>
    </Container>
  )
}
