import { useEffect, useState } from 'react'

import client, { Bot } from '@pxlbots/sdk'
import { orderBy } from 'lodash'
import styled from 'styled-components'

import CenterContainer from '../components/CenterContainer'
import { HEADER_HEIGHT } from '../components/GameContainer'
import StealModal from '../components/StealModal'
import { useGame } from '../context/GameContext'
import color from '../styles/mixins/color'

const Avatar = styled.img<{ width?: number }>`
  height: ${(props) => props.width || HEADER_HEIGHT}px;
  width: ${(props) => props.width || HEADER_HEIGHT}px;
`

const StealContainer = styled.div`
  position: absolute;
  left: ${HEADER_HEIGHT + 91}px;
  top: ${HEADER_HEIGHT + 40}px;
  bottom: 80px;
  right: 80px;
  width: auto;
  overflow-y: auto;
`

const StealBots = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
  position: relative;
`

const StealItem = styled.div<{ disabled?: boolean }>`
  position: relative;
  margin: 20px 20px 0 0;
  padding: 20px;
  flex-direction: row;
  width: 400px;

  ${(props) =>
    !props.disabled &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${color('darkblue')};
    }
    transition: background-color 0.2s ease-in-out;
`}
`

const StealItemDescription = styled.div`
  margin-left: 20px;
`

export default function BotSteal({ bot }: { bot: Bot }) {
  const [others, setOthers] = useState<Bot[] | null>(null)
  const [yours, setYours] = useState<Bot[]>([])
  const [selected, setSelected] = useState<Bot | undefined>()
  const { bots } = useGame()

  useEffect(() => {
    const fetchOthers = async () => {
      if (bot.location) {
        const result = await client.starbases.fetch(bot.location)
        const others = (await Promise.all(
          result.bots.map((b) => client.bots.fetch(b.tokenId)),
        )) as Bot[]
        setOthers(
          orderBy(
            others.filter(
              (b) =>
                bots.find((myBot) => myBot.tokenId === b.tokenId) === undefined,
            ),
            'tokenId',
            'asc',
          ),
        )
        setYours(
          orderBy(
            others.filter(
              (b) =>
                bots.find((myBot) => myBot.tokenId === b.tokenId) !==
                  undefined && b.tokenId !== bot.tokenId,
            ),
            'tokenId',
            'asc',
          ),
        )
      }
    }
    fetchOthers()
  }, [bot])

  if (!others) {
    return (
      <StealContainer>
        <CenterContainer bgColor="transparent">
          Scanning for Pxlbots
        </CenterContainer>
      </StealContainer>
    )
  }

  if (!others.length) {
    return (
      <StealContainer>
        <CenterContainer bgColor="transparent">
          No other Pxlbots found
        </CenterContainer>
      </StealContainer>
    )
  }

  return (
    <>
      <StealContainer>
        <h2>WHO'S HERE?</h2>
        {yours.length ? (
          <>
            <h4>YOUR PXLBOTS</h4>
            <StealBots>
              {yours.map((b) => {
                return (
                  <StealItem disabled key={b.tokenId}>
                    <Avatar
                      width={200}
                      src={b.image || '/images/sample-bot.png'}
                    />
                    <StealItemDescription>
                      <h4>{b.name}</h4>
                      <p>{b.faction}</p>
                    </StealItemDescription>
                  </StealItem>
                )
              })}
            </StealBots>
          </>
        ) : null}
        <h4>OTHER PXLBOTS</h4>
        <StealBots>
          {others.map((b) => {
            return (
              <StealItem key={b.tokenId} onClick={() => setSelected(b)}>
                <Avatar width={200} src={b.image || '/images/sample-bot.png'} />
                <StealItemDescription>
                  <h4>{b.name}</h4>
                  <p>{b.faction}</p>
                </StealItemDescription>
              </StealItem>
            )
          })}
        </StealBots>
      </StealContainer>
      <StealModal
        bot={bot}
        targetBot={selected}
        open={!!selected}
        onClose={() => setSelected(undefined)}
      />
    </>
  )
}
