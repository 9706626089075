import client, { Bot } from '@pxlbots/sdk'

import { useGame } from '../context/GameContext'
import { useLoading } from '../context/LoadingContext'

export const ORIGIN = 50
export const GRID = 10

export const MIN_EDGE = ORIGIN - GRID / 2
export const MAX_EDGE = ORIGIN + GRID / 2

export default function useJump(bot: Bot) {
  const loader = useLoading()
  const { updateBots } = useGame()
  return async (location: { x: string; y: string; z: string }) => {
    try {
      await client.missions.jump(bot.tokenId, location, (status) => {
        switch (status) {
          case 'loading':
            loader.startLoading('REQUESTING JUMP ACCESS...', true)
            break
          case 'waiting_for_submission':
          case 'approving':
            loader.startLoading('OPTIMIZING ROUTE…', true)
            break
          case 'submitting':
            loader.startLoading('PRIMING FOR JUMP...', true, 1)
            break
          default:
            break
        }
      })

      await updateBots()
    } catch (e) {}
    loader.stopLoading()
  }
}
