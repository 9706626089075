import { BotLocation } from '@pxlbots/sdk'

import { GRID, ORIGIN } from '../hooks/useJump'

export function formatZeroes(part: string) {
  return `000${part}`.slice(-GRID.toString().length)
}

export default function formatLocation(
  location?: BotLocation & { name?: string },
  noName?: boolean,
): string {
  if (location?.name && !noName) {
    return location.name.toUpperCase()
  }

  const { x, y, z } = location || {
    x: ORIGIN.toString(),
    y: ORIGIN.toString(),
    z: ORIGIN.toString(),
  }

  return `${formatZeroes(x)}.${formatZeroes(y)}.${formatZeroes(z)}`
}
