import { Link } from 'react-router-dom'
import styled from 'styled-components'

import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'

export const Tabs = styled.div`
  border-bottom: 1px solid ${color('dimmedblue')};
  position: absolute;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Tab = styled(Link)<{ selected?: boolean }>`
  color: ${(props) => (props.selected ? color('blue') : color('dimmedblue'))};
  text-decoration: none;
  padding: 20px 40px;
  font-size: ${toRem(18)};
  text-transform: uppercase;
  border-bottom: 2px solid
    ${(props) => (props.selected ? color('blue') : 'transparent')};
  margin-bottom: -2px;

  &:hover {
    color: ${color('blue')};
  }
  transition: color 0.2s ease-in-out;
`

export default Tab
