import { useMemo, useState } from 'react'

import client from '@pxlbots/sdk'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import color from '../styles/mixins/color'
import spacing from '../styles/mixins/spacing'
import toRem from '../styles/mixins/toRem'
import Spacer from './Spacer'
import Stack from './Stack'

const MintContainer = styled.div`
  width: ${toRem(260)};
  align-items: center;
  justify-content: center;
`

const CountContainer = styled.div`
  flex-direction: row;
  align-items: center;
`

const AmountContainer = styled.span`
  font-size: ${toRem(120)};
  padding: 0 ${spacing(5)};
  width: ${toRem(125)};
  text-align: center;
`

const CountButton = styled.button`
  width: ${toRem(65)};
  min-width: ${toRem(65)};
  height: ${toRem(65)};
  font-size: ${toRem(90)};
  border: none;

  &:hover {
    background-color: ${color('green')};
  }
`

function useBotPrice() {
  const [price, setPrice] = useState(0)

  useMemo(() => {
    client.account
      .connect(true)
      .then(() => client.bots.mintPrice().then(setPrice))
  }, [])

  return price
}

export default function Mint({ account }: { account: string }) {
  const botPrice = useBotPrice()
  const [count, setCount] = useState(1)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)

  const subtotal = useMemo(() => {
    return botPrice * count
  }, [count, botPrice])

  const handleCountMinus = () => setCount((c) => c - 1)
  const handleCountPlus = () => setCount((c) => c + 1)

  const handleMint = async () => {
    setLoading(true)
    try {
      const transaction = await client.bots.mint(1, false)
      await transaction.wait()
      const balance = await client.bots.balanceOf(account)
      setTotal(balance)
    } catch (e: any) {
      console.log(e)
      // TODO: handle error
    } finally {
      setLoading(false)
    }
  }

  if (total) {
    return (
      <Stack>
        <h1>Congratulations!</h1>
        <div>
          <h2>YOU NOW OWN {total} PXLBOT* AND CAN EXPLORE THE PXLVERSE.</h2>
          <Spacer v={10} />
          <div className="row">
            <Link className="button mint" to="/">
              Play Game
            </Link>
          </div>
        </div>
      </Stack>
    )
  }

  return (
    <Stack>
      <h2>
        EACH PXLBOT COSTS {botPrice} MATIC, EXCLUDING GAS FEES. YOU CAN BUY UP
        TO 3 PER TRANSACTION.
      </h2>

      <MintContainer>
        <CountContainer>
          <CountButton disabled={count === 1} onClick={handleCountMinus}>
            -
          </CountButton>
          <AmountContainer>{count}</AmountContainer>
          <CountButton disabled={count === 3} onClick={handleCountPlus}>
            +
          </CountButton>
        </CountContainer>
        <Spacer v={10} />
        <span>SUBTOTAL: {subtotal} MATIC</span>
        <Spacer v={4} />
        <button className="mint" disabled={loading} onClick={handleMint}>
          {loading ? 'Minting' : 'Mint'}
        </button>
      </MintContainer>
    </Stack>
  )
}
