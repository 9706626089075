import { useEffect, useState } from 'react'

import client, { Bot } from '@pxlbots/sdk'
import { AirdroppedMission } from '@pxlbots/sdk/dist/starbases'
import styled from 'styled-components'

import ActionIcons from '../assets/action-icons.png'
import Carousel from '../components/Carousel'
import { HEADER_HEIGHT } from '../components/GameContainer'
import { useToggleModal } from '../components/Modal'
import TravelModal from '../components/TravelModal'
import { useGame } from '../context/GameContext'
import { useLoading } from '../context/LoadingContext'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'
import formatLocation from '../utils/formatLocation'
import Starbase1 from './3D/Starbase1'
import BotPageContainer from './BotPageContainer'
import BotSteal from './BotSteal'
import MissionImage from './MissionImage'
import StationRenameModal from './StationRenameModal'

const CONTENT_CONTAINER_HEIGHT = `calc(100vh - ${HEADER_HEIGHT}px)`

const Subtitle = styled.div`
  color: ${color('blue')};
  text-transform: uppercase;
`

const Title = styled.div`
  font-size: ${toRem(48)};
  line-height: ${toRem(48)};
  text-transform: uppercase;
  flex-direction: row;
`

const OptionsContainer = styled.div`
  flex-direction: row;
  height: 85px;
  line-height: ${toRem(24)};
`

const Option = styled.div<{ selected?: boolean }>`
  margin-bottom: -35px;
  width: 70px;
  height: 120px;
  justify-content: space-between;
  text-transform: uppercase;
  overflow: hidden;
  padding-bottom: 15px;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
  font-size: ${toRem(14)};
  line-height: ${toRem(14)};

  border-bottom: 5px solid
    ${(props) => (props.selected ? color('blue') : 'transparent')};

  &:hover {
    border-bottom: 5px solid ${color('blue')};
  }
  transition: border-bottom 0.2s ease-in-out;
`

const OptionIcon = styled.div<{ index: number; selected?: boolean }>`
  background-image: url(${ActionIcons});
  background-position: -${(props) => props.index * 70}px ${(props) => (props.selected ? -70 : 0)}px;
  width: 70px;
  height: 70px;
  border: 1px solid ${color('blue')};
  &:hover {
    background-position: -${(props) => props.index * 70}px -70px;
  }
`

const CarouselItem = styled.div`
  width: 390px;
  padding: 40px;
  border: 1px solid ${color('blue')};
  background-color: ${color('transparentBlue')};
  height: calc(100% - 50px);
  margin: 20px 0;
  justify-content: space-between;
`

const CarouselItemTitle = styled.h4`
  margin-bottom: 20px;
`

const ContentContainer = styled.div`
  flex: 1;
  min-height: 1px;
`

const ItemDescription = styled.div`
  height: 100%;
  overflow-y: auto;
  min-height: 1px;
`
const ButtonContainer = styled.div`
  height: 65px;
  margin-top: 20px;
`

const MissionsContainer = styled.div`
  position: absolute;
  left: 80px;
  top: ${HEADER_HEIGHT + 40}px;
  bottom: 80px;
  right: 80px;
  width: auto;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
`

function BotMissions({ bot }: { bot: Bot }) {
  const [missions, setMissions] = useState<AirdroppedMission[] | undefined>(
    undefined,
  )

  const game = useGame()

  const loader = useLoading()

  useEffect(() => {
    const getData = async () => {
      const { x, y, z } = bot.location || {}
      if (x && y && z) {
        try {
          loader.startLoading('RETRIEVING MISSIONS...', true)
          const missions = (await client.starbases.getAvailableMissions(
            { x, y, z },
            bot.tokenId,
          )) as AirdroppedMission[]

          setMissions(missions)
        } catch (e) {
          setMissions([])
        }
      }
      loader.stopLoading()
    }
    getData()
  }, [bot])

  if (!missions) return null

  if (!missions.length) {
    return (
      <MissionsContainer>
        All missions have been completed for this Starbase.
      </MissionsContainer>
    )
  }

  return (
    <Carousel
      itemHeight={CONTENT_CONTAINER_HEIGHT}
      itemWidth={420}
      offset={390}>
      {missions.map((m, index) => {
        return (
          <CarouselItem key={`mission-${index}`}>
            <ContentContainer>
              <CarouselItemTitle>MISSION #{index + 1}</CarouselItemTitle>
              <CarouselItemTitle>{m.title}</CarouselItemTitle>
              <MissionImage mission={m}></MissionImage>
              <ContentContainer>
                <ItemDescription>{m.message}</ItemDescription>
              </ContentContainer>
            </ContentContainer>
            <ButtonContainer>
              <button
                className="wallet"
                disabled={!!loader.loading}
                onClick={async () => {
                  loader.startLoading('Starting mission', true)
                  try {
                    await client.missions.beginAirdroppedMission(
                      bot.tokenId,
                      m.contract_id,
                    )
                  } catch (e: any) {
                    console.log(e)
                  }
                  await game.updateBots()
                  loader.stopLoading()
                }}>
                Begin Mission
              </button>
            </ButtonContainer>
          </CarouselItem>
        )
      })}
    </Carousel>
  )
}

export default function BotIdle({ bot }: { bot: Bot }) {
  const [option, setOption] = useState('Missions')
  const { open: travelOpen, toggle: toggleTravel } = useToggleModal()
  const { open: renameOpen, toggle: toggleRename } = useToggleModal()

  const renderItems = () => {
    switch (option) {
      case 'Missions':
        return <BotMissions bot={bot} />
      case 'Steal':
        return <BotSteal bot={bot} />

      default:
        return null
    }
  }

  return (
    <>
      <Starbase1 />
      <BotPageContainer
        bot={bot}
        header={
          <>
            <Subtitle>
              {bot.name} // Loc: {formatLocation(bot.location, true)}
            </Subtitle>
            <Title>
              {!bot.location?.name ? 'Station: ' : null}
              {formatLocation(bot.location)}{' '}
            </Title>
            <OptionsContainer>
              <Option onClick={() => toggleTravel()}>
                <OptionIcon index={4} />
                Jump
              </Option>
              <Option
                onClick={() => setOption('Missions')}
                selected={option === 'Missions'}>
                <OptionIcon index={2} selected={option === 'Missions'} />
                Missions
              </Option>
              <Option
                onClick={() => setOption('Steal')}
                selected={option === 'Steal'}>
                <OptionIcon index={0} selected={option === 'Steal'} />
                Steal
              </Option>
              <Option onClick={() => toggleRename()}>
                <OptionIcon index={1} />
                Rename
              </Option>
            </OptionsContainer>
          </>
        }>
        {renderItems()}
      </BotPageContainer>
      <TravelModal open={travelOpen} onClose={toggleTravel} bot={bot} />
      <StationRenameModal open={renameOpen} onClose={toggleRename} bot={bot} />
    </>
  )
}
