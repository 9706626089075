import { useEffect } from 'react'

import { useEthers } from '@usedapp/core'
import { Route, Routes, useNavigate } from 'react-router-dom'

import GameContainer from '../components/GameContainer'
import GameProvider from '../context/GameContext'
import AccountSettings from './AccountSettings'
import AvailableScions from './AvailableScions'
import Bot from './Bot'
import Bots from './Bots'

export default function Armory() {
  const { account } = useEthers()

  const navigate = useNavigate()

  useEffect(() => {
    if (!account) {
      navigate('/')
    }
  }, [account])

  if (!account) return null

  return (
    <GameProvider>
      <GameContainer>
        <Routes>
          <Route path="/" element={<Bots />} />
          <Route path="/bot/:id" element={<Bot />} />
          <Route path="/settings" element={<AccountSettings />} />
          <Route path="/available-scions" element={<AvailableScions />} />
        </Routes>
      </GameContainer>
    </GameProvider>
  )
}
