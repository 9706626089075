import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import styled from 'styled-components'

import LoaderAnimation0 from '../assets/loader-animation.gif'
import LoaderAnimation1 from '../assets/loader-animation1.gif'
import LoaderAnimation2 from '../assets/loader-animation2.gif'
import LoaderAnimation3 from '../assets/loader-animation3.gif'
import color from '../styles/mixins/color'

const LoaderContext = createContext<{
  startLoading: (message: string, clear?: boolean, animation?: number) => string
  stopLoading: (id?: string) => void
  loading: { id: string; message: string } | null
}>({ startLoading: () => '', stopLoading: () => {}, loading: null })

const LoadingContainer = styled.div`
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`
const LoadingBG = styled.div`
  position: absolute;
  background-color: ${color('darkblue')};
  opacity: 0.85;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Loader = styled.img`
  margin-bottom: 20px;
`

const LoadingArea = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
`

const LOADING_ANIMATIONS = [
  LoaderAnimation0,
  LoaderAnimation1,
  LoaderAnimation2,
  LoaderAnimation3,
]

export default function LoadingProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<{
    [id: string]: { message: string; animation?: number }
  }>({})

  const startLoading = useCallback(
    (message: string, clear?: boolean, animation?: number) => {
      const id = Date.now().toString()
      if (clear) {
        setLoading({ [id]: { message, animation } })
        return id
      }
      setLoading((l) => ({ ...l, [id]: { message, animation } }))
      return id
    },
    [],
  )

  const stopLoading = useCallback((id?: string) => {
    if (!id) {
      setLoading({})
      return
    }

    setLoading((l) => {
      const newL = { ...l }
      delete newL[id]
      return newL
    })
  }, [])

  const currentLoading = useMemo(() => {
    const key = Object.keys(loading).filter((key) => loading[key])[0]
    if (!key) return null
    return {
      id: key,
      message: loading[key].message,
      animation: LOADING_ANIMATIONS[loading[key].animation || 0],
    }
  }, [loading])

  return (
    <>
      <LoaderContext.Provider
        value={{
          startLoading,
          stopLoading,
          loading: currentLoading,
        }}>
        {children}
      </LoaderContext.Provider>
      {currentLoading ? (
        <LoadingContainer>
          <LoadingBG />
          <LoadingArea>
            <Loader src={currentLoading.animation} />
            <h2>{currentLoading.message}</h2>
          </LoadingArea>
        </LoadingContainer>
      ) : null}
    </>
  )
}

export function useLoading() {
  return useContext(LoaderContext)
}
