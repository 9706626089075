import { useEffect } from 'react'

import { useEthers } from '@usedapp/core'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Logo from '../assets/logo-new.svg'
import Background from '../assets/nebula_dithered.png'
import ResponsiveImage from '../components/ResponsiveImage'

const Main = styled.main`
  background: url(${Background}) no-repeat center center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  font-family: 'Unica One', sans-serif !important;
  text-trandform: uppercase;
  font-size: 24px;
  line-height: 1.2;
  padding: 40px;
  overflow-y: auto;
`

const Title = styled.h1`
  font-size: 60px;
  text-align: center;
  margin: 20px 0;
`

const MintLink = styled.p`
  text-align: center;
  margin: 60px 0;
  font-size: 36px;
  line-height: 1.2;
  text-transform: uppercase;
`
const SublineLink = styled.span`
  font-size: 24px;
`

const Footer = styled.p`
  text-align: center;
  margin-top: 60px;
  text-transform: uppercase;
  max-width: 554px;
`

export default function Index() {
  const { account, activateBrowserWallet } = useEthers()

  const navigate = useNavigate()

  useEffect(() => {
    if (account) {
      navigate('/armory')
    }
  }, [account])

  return (
    <Main>
      <ResponsiveImage maxWidth={420} src={Logo} />
      <Title>ENTER THE PXLVERSE</Title>
      <button className="wallet" onClick={() => activateBrowserWallet()}>
        Connect Metamask
      </button>
      <MintLink>
        DON’T HAVE YOUR PXLBOT YET?
        <br />
        <SublineLink>
          GO TO{' '}
          <a href="https://opensea.io/collection/pxlbots" target="_blank">
            https://opensea.io/collection/pxlbots
          </a>
        </SublineLink>
      </MintLink>
      <Footer>
        PXLBOTS IS A COLLECTIBLE NFT AND BLOCKCHAIN GAME, AN INTERGALACTIC
        BATTLE FOR SURVIVAL AND RESOURCES WHERE YOUR ACTIONS—AND FACTIONS—SHAPE
        THE PXLVERSE!
        <br />
        <br />
        <span>
          JOIN THE UPRISING AT <a href="https://pxlbots.com">PXLBOTS.COM</a>
        </span>
      </Footer>
    </Main>
  )
}
