import { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import color from '../styles/mixins/color'
import BalanceBox from './BalanceBox'
import { BANNER_HEIGHT } from './BetaBanner'

export const HEADER_HEIGHT = 300 + BANNER_HEIGHT

const GameContainer = styled.main`
  position: fixed;
  top: ${BANNER_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${color('deepblue')};
`

const HeaderContainer = styled.header`
  height: ${HEADER_HEIGHT}px;
  position: relative;
`

export default GameContainer

export function Header(props: { children: React.ReactNode; bot?: Bot }) {
  return (
    <HeaderContainer>
      {props.children}
      <BalanceBox bot={props.bot} />
    </HeaderContainer>
  )
}
