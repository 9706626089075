import { Suspense, useRef } from 'react'

import { useGLTF } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import styled from 'styled-components'
import * as THREE from 'three'
const Wrapper = styled.div`
  position: absolute;
  right: -30vw;
  top: 0;
  bottom: 0;
`
const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.9;
`

function Model({ ...props }) {
  const group = useRef<THREE.Group>(null)
  // @ts-ignore
  const { nodes, materials } = useGLTF('/starbase1.gltf')

  useFrame(({ clock }) => {
    if (group.current) {
      group.current.rotation.y = (clock.getElapsedTime() / 18) * Math.PI
      group.current.rotation.z = 0.3 * (clock.getElapsedTime() / 18)
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[0, 0, 0]}>
          <mesh
            geometry={nodes.pl0.geometry}
            material={
              materials['Material_4D981EB1-805E-45BC-9AE8-49795076FC42_1']
            }
          />
        </group>
      </group>
    </group>
  )
}

export default function Starbase1() {
  return (
    <Wrapper>
      <Background className="bg">
        <Canvas>
          <ambientLight intensity={0.7} />
          <directionalLight position={[-2, 4, 2]} intensity={0.6} />
          <Suspense fallback={null}>
            <Model scale={0.12} />
          </Suspense>
        </Canvas>
      </Background>
    </Wrapper>
  )
}
