import m1337 from '@pxlbots/sdk/dist/manifests/1337.json'
import { useContractFunction } from '@usedapp/core'
import { Contract } from 'ethers'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Logo from '../assets/logo.svg'
import spacing from '../styles/mixins/spacing'
import ResponsiveImage from './ResponsiveImage'

const HeaderContainer = styled.header`
  padding: ${spacing(30)};
  align-items: center;
  justify-content: center;
`

export function ApproveOwner() {
  const txn = useContractFunction(
    new Contract(m1337.AlphaMinter.address, m1337.AlphaMinter.abi),
    'setCollectibleTokenOwner',
  )
  const send = () => {
    txn.send(586, '0xd6B6c1D686D67A3cda450d31FC9d4463f7a1BfaC')
  }
  return <button onClick={send}>Approve</button>
}

export function TokenClaimer() {
  const txn = useContractFunction(
    new Contract(m1337.AlphaMinter.address, m1337.AlphaMinter.abi),
    'claimFromCollectible',
  )
  const send = () => {
    txn.send(586)
  }
  return <button onClick={send}>Claim</button>
}

export default function Header() {
  return (
    <HeaderContainer>
      {/* <ApproveOwner />
      <TokenClaimer /> */}
      <Link to="/">
        <ResponsiveImage src={Logo} />
      </Link>
    </HeaderContainer>
  )
}
