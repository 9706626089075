export default {
  black: '#000000',
  white: '#FFFFFF',
  grey: '#666666',
  green: '#A3CE27',
  blue: '#0AABD2',
  dimmedblue: '#1B6276',
  darkblue: '#0C2932',
  deepblue: '#031621',
  yellow: '#F9CD4C',
  transparentBlue: '#04162175',
}
