import { useEffect, useState } from 'react'

export default function useScreenSize(maxWidth: number = 0) {
  const [screenSize, setScreenSize] = useState(0)

  useEffect(() => {
    const getScreenSize = () => {
      setScreenSize(Math.max(window.innerWidth, maxWidth))
    }
    getScreenSize()

    window.addEventListener('resize', getScreenSize)
    return () => {
      window.removeEventListener('resize', getScreenSize)
    }
  }, [])

  return screenSize
}
