import styled from 'styled-components'

import SampleImage from '../assets/pxlbot_samples.png'
import color from '../styles/mixins/color'
import spacing from '../styles/mixins/spacing'
import Header from './Header'

const Container = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 1800px;
`

const Block = styled.div<{
  color: string
  top?: number
  right?: number
  bottom?: number
  left?: number
}>`
  width: ${spacing(24)};
  height: ${spacing(24)};
  background-color: ${(props) => props.color};
  position: absolute;
  top: ${(props) => (props.top !== undefined ? spacing(props.top) : 'initial')};
  right: ${(props) =>
    props.right !== undefined ? spacing(props.right) : 'initial'};
  bottom: ${(props) =>
    props.bottom !== undefined ? spacing(props.bottom) : 'initial'};
  left: ${(props) =>
    props.left !== undefined ? spacing(props.left) : 'initial'};
`

const Main = styled.main`
  padding: 0 ${spacing(24)};
  flex-direction: row;
  min-height: 576px;
  @media (max-width: 1600px) {
    flex-direction: column;
  }
`

const Sample = styled.div`
  min-width: 576px;
  min-height: 100%;
  width: auto;
  background: url('${SampleImage}') no-repeat center center;
  background-size: cover;
`
const Content = styled.div`
  padding: ${spacing(20)};
  position: relative;
  background-color: ${color('grey')};
  flex: 1;
`

export default function PageLayout(props: { children: any }) {
  return (
    <>
      <Header />
      <Container>
        <Block color="#EB2A19" bottom={0} left={0} />
        <Block color="#FB922B" bottom={-24} left={0} />
        <Block color="#FFC600" bottom={-24} left={24} />
        <Block color="#666666" top={-24} right={0} />
        <Block color="#333333" top={0} right={0} />
        <Block color="#CCCCCC" top={-24} right={24} />
        <Main>
          <Sample />
          <Content>{props.children}</Content>
        </Main>
      </Container>
    </>
  )
}
