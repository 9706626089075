import client, { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import ArrowDown from '../assets/arrow-down.svg'
import { useGame } from '../context/GameContext'
import { useLoading } from '../context/LoadingContext'
import BotAttributes from './BotAttributes'
import Modal from './Modal'

const Content = styled.div`
  flex-direction: row;
  align-items: top;
  justify-content: center;
  flex: 1;
`

const BotBlock = styled.div`
  width: 300px;
  margin: 20px;
`

const BotImage = styled.img`
  width: 300px;
  margin-bottom: 20px;
`

const Arrows = styled.div`
  width: 150px;
  height: 180px;
  margin-top: 90px;
  align-items: center;
  justify-content: space-between;
`
const Arrow = styled.img`
  transform: rotate(90deg);
`

export default function StealModal(props: {
  bot: Bot
  targetBot?: Bot
  onClose: () => any
  open?: boolean
}) {
  const { updateBots } = useGame()
  const loader = useLoading()
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      title="Steal"
      subtitle="PXL"
      buttonLabel={`Attempt to Steal from ${props.targetBot?.name}`}
      form={{
        onSubmit: async (payload) => {
          try {
            if (props.targetBot) {
              await client.missions.steal(
                props.bot.tokenId,
                props.targetBot.tokenId,
                (status) => {
                  switch (status) {
                    case 'loading':
                      loader.startLoading('Preparing to steal', true)
                      break
                    case 'waiting_for_approval':
                      loader.startLoading('Awaiting stealing approval', true)
                      break
                    case 'approving':
                      loader.startLoading('Approving stealing', true)
                      break
                    case 'waiting_for_submission':
                      loader.startLoading('Awaiting stealing instruction', true)
                      break
                    case 'submitting':
                      loader.startLoading('Trying to steal PXL', true)
                  }
                },
              )
              await updateBots()
            }
          } catch (e) {}
          loader.stopLoading()
          payload.onFinish()
          props.onClose()
        },
      }}>
      <Content>
        <BotBlock>
          <BotImage src={props.bot.image} />
          <h4>{props.bot.name}</h4>
          <BotAttributes bot={props.bot} />
        </BotBlock>
        <Arrows>
          <Arrow src={ArrowDown} />
          <Arrow src={ArrowDown} />
        </Arrows>
        <BotBlock>
          <BotImage src={props.targetBot?.image} />
          <h4>{props.targetBot?.name}</h4>
          <BotAttributes bot={props.targetBot as Bot} />
        </BotBlock>
      </Content>
    </Modal>
  )
}
