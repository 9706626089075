import { useEffect, useMemo, useState } from 'react'

import { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import { useGame } from '../context/GameContext'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'
import formatThousands from '../utils/formatThousands'

const SHOW_CLAIM_ALL = false

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 10vw;
  width: auto;
  flex-direction: row;
`
const BotContainer = styled(Container)`
  position: absolute;
  top: 20px;
  right: 40px;
`

const Balance = styled.div`
  width: auto;
  background-color: ${color('darkblue')};
  height: 100px;
  min-width: 300px;
  font-size: ${toRem(48)};
  line-height: ${toRem(48)};
  padding: 10px 30px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-left: 10px;
  text-align: right;
`

const BalanceBot = styled(Balance)<{ blink?: boolean }>`
  height: 70px;
  font-size: ${toRem(36)};
  line-height: ${toRem(36)};
  min-width: 1px;
  margin-left: 0;
  background-color: ${color('deepblue')};
  padding: 20px;
  border: 1px solid ${color('blue')};
  transition: all 0.3s;
  ${(props) =>
    props.blink &&
    `
    background-color: ${color('blue')};

`}
`

const PXLLabel = styled.div`
  height: 100%;
  font-size: ${toRem(18)};
  line-height: ${toRem(18)};
  padding-right: 20px;
  text-align: left;
`

const Blue = styled.span`
  color: ${color('blue')};
`

const Button = styled.button`
  min-width: 1px;
  margin-left: 20px;
`

const LinkContainer = styled.div`
  height: 100px;
  padding: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
`

const Link = styled.a`
  color: ${color('blue')};
`

function BalanceBoxBot(props: { bot?: Bot }) {
  const botBalance = props.bot?.inventory?.pxl || 0
  const [blink, setBlink] = useState<boolean | undefined>()

  useEffect(() => {
    if (blink === undefined) {
      setBlink(false)
      return
    }
    setBlink(true)
    const timer = setTimeout(() => {
      setBlink(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [botBalance])

  return (
    <BotContainer>
      <BalanceBot blink={blink}>
        <PXLLabel>
          <Blue>$PXL</Blue>
        </PXLLabel>
        <span>{formatThousands(botBalance)}</span>
      </BalanceBot>
    </BotContainer>
  )
}
function BalanceGlobal() {
  const { balance, bots } = useGame()

  const botsTotal = useMemo(() => {
    return bots.map((b) => b.inventory?.pxl || 0).reduce((a, b) => a + b, 0)
  }, [bots])

  return (
    <Container>
      <LinkContainer>
        <Link href="https://faucet.polygon.technology/" target="_new">
          GET TEST MATIC
        </Link>
      </LinkContainer>
      <Balance>
        <PXLLabel>$PXL</PXLLabel>
        <span>{formatThousands(balance)}</span>
      </Balance>
      {botsTotal > 0 ? (
        <Balance>
          <span>
            +<Blue>{formatThousands(botsTotal)}</Blue>
          </span>
          {SHOW_CLAIM_ALL ? <Button>Claim all</Button> : null}
        </Balance>
      ) : null}
    </Container>
  )
}
export default function BalanceBox(props: { bot?: Bot }) {
  if (props.bot) {
    return <BalanceBoxBot bot={props.bot} />
  }

  return <BalanceGlobal />
}
