import { useEffect, useState } from 'react'

import client, { Bot } from '@pxlbots/sdk'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import AddNewBotImage from '../assets/add-bot.svg'
import AccountTitle, { TITLE_OFFSET } from '../components/AccountTitle'
import { useBotIntransitState } from '../components/BotIntransit'
import Carousel from '../components/Carousel'
import ClaimModal from '../components/ClaimModal'
import { Header, HEADER_HEIGHT } from '../components/GameContainer'
import TopMenu from '../components/TopMenu'
import { useGame } from '../context/GameContext'
import { useLoading } from '../context/LoadingContext'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'
import formatLocation from '../utils/formatLocation'
import formatThousands from '../utils/formatThousands'
import isAlertStatus from '../utils/isAlertStatus'

export const BOT_CONTAINER_WIDTH = 350
export const BOT_CONTAINER_PADDING = 20
export const BOT_CONTAINER_HEIGHT = `calc(100vh - ${HEADER_HEIGHT}px)`

export const BotContainer = styled.div`
  width: ${BOT_CONTAINER_WIDTH + BOT_CONTAINER_PADDING}px;
  height: ${BOT_CONTAINER_HEIGHT};
  padding-top: ${BOT_CONTAINER_PADDING}px;
  padding-right: ${BOT_CONTAINER_PADDING / 2}px;
  padding-left: ${BOT_CONTAINER_PADDING / 2}px;
  padding-bottom: ${BOT_CONTAINER_PADDING}px;
  overflow-y: auto;
`

const NewBotItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color('blue')};
  color: ${color('blue')};
  height: ${BOT_CONTAINER_WIDTH}px;
  align-items: center;
  justify-content: flex-end;
  padding: 50px;
  text-transform: uppercase;
  text-decoration: none;
  background: url(${AddNewBotImage}) no-repeat center 50px;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #0aabd240;
  }
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;
  display: flex;
`

export const BotItem = styled.div<{ url: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${BOT_CONTAINER_WIDTH + BOT_CONTAINER_PADDING * 2}px
    ${BOT_CONTAINER_PADDING}px ${BOT_CONTAINER_PADDING}px
    ${BOT_CONTAINER_PADDING}px;
  text-transform: uppercase;
  text-decoration: none;
  background: url(${(props) => props.url}) no-repeat center top;
  background-size: ${BOT_CONTAINER_WIDTH}px ${BOT_CONTAINER_WIDTH}px;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #0aabd240;
  }
  transition: background-color 0.2s ease-in-out;
`

export const BotItemName = styled.div`
  font-size: ${toRem(24)};
  line-height: ${toRem(24)};
  padding-bottom: ${BOT_CONTAINER_PADDING / 2}px;
  flex-direction: row;
  align-items: center;
`

export const BotItemDetails = styled.div`
  padding: ${BOT_CONTAINER_PADDING / 4}px 0;
`
const LocationDetails = styled(BotItemDetails)`
  min-height: 60px;
`

const BotBalance = styled.div`
  color: ${color('blue')};
  font-size: ${toRem(48)};
  line-height: ${toRem(48)};
  padding: ${BOT_CONTAINER_PADDING / 2}px 0;
  height: ${toRem(100)};
  align-items: center;
  flex-direction: row;
`

export const ClaimButton = styled.button`
  flex-grow: 0;
  margin-left: ${BOT_CONTAINER_PADDING}px;
  min-width: 1px;
`

const pulse = keyframes`
 0% { background-color: transparent; }
 50% {background-color: #cc0000;}
 100% { background-color: transparent; }
 `

const Alert = styled.div`
  margin-left: ${BOT_CONTAINER_PADDING / 2}px;
  width: 16px;
  height: 16px;
  animation-name: ${pulse};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`

const ETA = styled.div`
  font-size: ${toRem(14)};
`

function BotInTransitStarbase({ bot }: { bot: Bot }) {
  const { progress, bases, hours, destination } = useBotIntransitState(bot)

  return (
    <>
      Jumping TO SB // {formatLocation(destination)}
      <br />
      <ETA>
        {hours > 0.01 ? `ETA // ${hours.toFixed(2)} T-HS` : 'Arriving...'} (
        {Math.round((progress / bases) * 100)}%)
      </ETA>
    </>
  )
}

function BotDestination({ bot }: { bot: Bot }) {
  const destination = bot.travelInfo?.metadata.destination
  return (
    <>
      Status: ARRIVED
      <br />
      SB // {formatLocation(destination)}
    </>
  )
}

function BotPosition({ bot }: { bot: Bot }) {
  switch (bot.status) {
    case 'in-transit':
      return <BotInTransitStarbase bot={bot} />
    case 'mission-complete':
      return <BotDestination bot={bot} />
    default:
      return <>SB // {formatLocation(bot.location)}</>
  }
}

export default function Bots() {
  const { bots, refresh } = useGame()

  const loader = useLoading()

  const [selectedBot, setSelectedBot] = useState<Bot | undefined>()

  const navigate = useNavigate()

  const location = useLocation()
  useEffect(() => {
    if (location.pathname === '/armory') {
      refresh()
    }
  }, [location.pathname])

  return (
    <>
      <Header>
        <AccountTitle>Select your PXLBOT</AccountTitle>
        <TopMenu />
      </Header>
      <Carousel
        itemWidth={BOT_CONTAINER_WIDTH + BOT_CONTAINER_PADDING}
        itemHeight={BOT_CONTAINER_HEIGHT}
        offset={TITLE_OFFSET - BOT_CONTAINER_PADDING}>
        {[
          ...bots.map((bot) => (
            <BotContainer key={bot.tokenId}>
              <BotItem
                onMouseUp={(e) => {
                  if (bot.inPlay) {
                    e.preventDefault()
                    navigate(`/armory/bot/${bot.tokenId}`)
                  }
                }}
                url={bot.image || '/images/sample-bot.png'}>
                <BotItemName>
                  {bot.name}
                  {isAlertStatus(bot.status) ? <Alert /> : null}
                </BotItemName>
                <BotItemDetails>{bot.faction}</BotItemDetails>
                <BotItemDetails>CLASS: {bot.class}</BotItemDetails>
                {bot.inPlay ? (
                  <>
                    <LocationDetails>
                      <BotPosition bot={bot} />
                    </LocationDetails>
                    <BotBalance>
                      {formatThousands(bot.inventory?.pxl || 0)}
                      {bot.status !== 'in-transit' &&
                      (bot.inventory?.pxl || 0) > 0 ? (
                        <ClaimButton
                          onMouseUp={(e) => {
                            e.stopPropagation()
                            setSelectedBot(bot)
                          }}>
                          Transfer
                        </ClaimButton>
                      ) : null}
                    </BotBalance>
                  </>
                ) : (
                  <button
                    className="wallet mt"
                    onMouseUp={async (e) => {
                      e.stopPropagation()
                      loader.startLoading('Entering game...', true)
                      try {
                        await client.bots.addToGame(bot.tokenId)
                        refresh()
                        navigate(`/armory/bot/${bot.tokenId}`)
                      } catch (e) {}
                      loader.stopLoading()
                    }}>
                    Add to Game
                  </button>
                )}
              </BotItem>
            </BotContainer>
          )),

          <BotContainer key="add-new">
            <NewBotItem
              onClick={() => {
                window.open('https://opensea.io/collection/pxlbots')
              }}>
              ADD NEW
            </NewBotItem>
          </BotContainer>,
        ]}
      </Carousel>
      <ClaimModal
        open={!!selectedBot}
        onClose={() => setSelectedBot(undefined)}
        bot={selectedBot}
      />
    </>
  )
}
