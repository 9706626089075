import client, { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import { useGame } from '../context/GameContext'
import { useLoading } from '../context/LoadingContext'
import formatLocation from '../utils/formatLocation'
import formatThousands from '../utils/formatThousands'
import Modal, { ModalInput } from './Modal'

const Content = styled.div`
  flex-direction: row;
  max-width: 800px;
  flex: 1;
  align-items: center;
`

type Form = {
  name: string
}

export default function StationRenameModal(props: {
  bot?: Bot
  onClose: () => any
  open?: boolean
}) {
  const { updateBots, updateBalance } = useGame()
  const loader = useLoading()

  const enoughBalance =
    (props.bot?.inventory?.pxl || 0) >= (props.bot?.location?.namePrice || 1)

  return (
    <Modal<Form>
      open={props.open}
      onClose={props.onClose}
      title="Rename"
      subtitle="Starbase"
      buttonLabel="Rename Starbase"
      disableButton={!enoughBalance}
      form={{
        initialValues: {
          name: props.bot?.location?.name || '',
        },
        onSubmit: async (payload) => {
          try {
            if (props.bot && props.bot.location && payload.valid) {
              await client.starbases.rename(
                props.bot.tokenId,
                payload.values.name,
                props.bot.location,
                (status) => {
                  switch (status) {
                    case 'loading':
                      loader.startLoading('Preparing to rename', true)
                      break
                    case 'waiting_for_approval':
                      loader.startLoading('Awaiting renaming approval', true)
                      break
                    case 'approving':
                      loader.startLoading('Approving new name', true)
                      break
                    case 'waiting_for_submission':
                      loader.startLoading('Awaiting rename signature', true)
                      break
                    case 'submitting':
                      loader.startLoading('Renaming starbase', true)
                      break
                  }
                },
              )
              await updateBots()
              await updateBalance()
            }
          } catch (e) {}
          loader.stopLoading()
          payload.onFinish()
          props.onClose()
        },
      }}>
      <p className="mb">
        IT CURRENTLY COSTS{' '}
        <span className="blue">
          {formatThousands(props.bot?.location?.namePrice || 0)} PXL{' '}
        </span>
        TO RENAME THIS STATION.
      </p>
      {!enoughBalance ? (
        <p className="mb yellow">YOU DO NOT HAVE ENOUGH PXL FOR THIS TASK.</p>
      ) : null}
      <Content>
        <ModalInput
          disabled={!enoughBalance}
          name="name"
          label="NEW NAME (21 CHAR MAX):"
          required
          placeholder={`SB // ${formatLocation(props.bot?.location)}`}
          maxLength={21}
        />
      </Content>
    </Modal>
  )
}
