import client, { Bot } from '@pxlbots/sdk'
import styled from 'styled-components'

import { useGame } from '../context/GameContext'
import { useLoading } from '../context/LoadingContext'
import color from '../styles/mixins/color'
import toRem from '../styles/mixins/toRem'
import formatLocation from '../utils/formatLocation'
import getDistance from '../utils/getDistance'
import BotPageContainer from './BotPageContainer'
import { HEADER_HEIGHT } from './GameContainer'
import MissionImage from './MissionImage'

const Subtitle = styled.div`
  color: ${color('blue')};
  text-transform: uppercase;
`

const Title = styled.div`
  font-size: ${toRem(48)};
  line-height: ${toRem(48)};
  text-transform: uppercase;
`

const OptionsContainer = styled.p`
  flex-direction: row;
  margin-bottom: 24px;
  line-height: ${toRem(24)};
`

const MissionCompleteReport = styled.div`
  position: absolute;
  left: ${HEADER_HEIGHT + 91}px;
  top: ${HEADER_HEIGHT - 1}px;
  bottom: 80px;
  right: 80px;
  width: auto;
  overflow: hidden;
  border: 1px solid ${color('blue')};
  line-height: ${toRem(24)};

  justify-content: space-between;
`

const MissionTitle = styled(Title)`
  padding-bottom: 40px;
`

const MissionContent = styled.div`
  padding: 40px;
  overflow-y: auto;
  flex: 1;
  height: auto;
`

const MissionFooter = styled.div`
  padding: 40px;
  border-top: 1px solid ${color('blue')};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const MissionCompleteTopRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
`

// const MissionPrize = styled.span`
//   font-size: ${toRem(36)};
//   line-height: ${toRem(36)};
//   color: ${color('blue')};
// `

function JumpComplete({ bot }: { bot: Bot }) {
  const loader = useLoading()
  const { updateBots } = useGame()

  return (
    <BotPageContainer
      bot={bot}
      header={
        <>
          <Subtitle>{bot.name} // Status:</Subtitle>
          <Title>Arrival</Title>
          <OptionsContainer />
        </>
      }>
      <MissionCompleteReport>
        <MissionContent>
          <MissionTitle>Jump Report</MissionTitle>
          <OptionsContainer>
            {bot.name.toUpperCase()} JUMPED FROM{' '}
            <span className="blue"> {formatLocation(bot.location)} </span> TO{' '}
            <span className="blue">
              {formatLocation(bot.travelInfo?.metadata.destination)}
            </span>
          </OptionsContainer>
          <OptionsContainer>
            ({getDistance(bot.travelInfo?.metadata.destination, bot.location)}{' '}
            STARBASES)
          </OptionsContainer>
          <OptionsContainer>
            ENCOUNTERS: NONE <br /> COSMIC DAMAGE: NONE
          </OptionsContainer>
          <OptionsContainer>
            (PXL REWARD AWARDED FOR FIRST TIME BASE DISCOVERY, MAY BE REDUCED
            DUE TO TRIBUTE)
          </OptionsContainer>
        </MissionContent>
        <MissionFooter>
          <button
            disabled={!!loader.loading}
            className="wallet"
            onClick={async () => {
              const mission = bot.missions[0]
              if (mission) {
                try {
                  await mission.end((status) => {
                    switch (status) {
                      case 'waiting_for_submission':
                        loader.startLoading(
                          'Requesting to enter starbase...',
                          true,
                        )
                        break
                      case 'submitting':
                        loader.startLoading('Entering starbase...', true, 2)
                        break
                    }
                  })
                  await updateBots()
                } catch (e) {}
                loader.stopLoading()
              }
            }}>
            {loader.loading?.message || 'Enter Starbase'}
          </button>
        </MissionFooter>
      </MissionCompleteReport>
    </BotPageContainer>
  )
}

function AirdropMissionComplete({ bot }: { bot: Bot }) {
  const loader = useLoading()
  const { updateBots } = useGame()
  const mission = bot.missions[0]

  if (!mission?.details) {
    return null
  }

  return (
    <BotPageContainer
      bot={bot}
      header={
        <>
          <MissionCompleteTopRow>
            <div>
              <Subtitle>{bot.name} // Status:</Subtitle>
              <Title>Mission Complete</Title>
              <OptionsContainer>
                MISSION DETAILS: {bot.missions[0]?.details?.message}
              </OptionsContainer>
            </div>
            <MissionImage mission={bot.missions[0]?.details}></MissionImage>
          </MissionCompleteTopRow>
          {/* <OptionsContainer /> */}
        </>
      }>
      <MissionCompleteReport>
        <MissionContent>
          <MissionTitle>Mission Report</MissionTitle>
          <OptionsContainer>
            {bot.name.toUpperCase()} JUMPED FROM{' '}
            <span className="blue"> {formatLocation(bot.location)} </span> TO{' '}
            <span className="blue">
              {formatLocation(bot.travelInfo?.metadata.destination)}
            </span>
          </OptionsContainer>
          <OptionsContainer>
            ({getDistance(bot.travelInfo?.metadata.destination, bot.location)}{' '}
            STARBASES)
          </OptionsContainer>

          <OptionsContainer>STARBASE DISCOVERY REWARD ADDED</OptionsContainer>
          <OptionsContainer>
            (REWARD MAY BE REDUCED DUE TO TRIBUTE)
          </OptionsContainer>
        </MissionContent>
        <MissionFooter>
          <button
            disabled={!!loader.loading}
            className="wallet"
            onClick={async () => {
              if (!mission.details) return
              try {
                await client.missions.endAirdroppedMission(
                  bot.tokenId,
                  mission.details?.contract_id,
                  (status) => {
                    switch (status) {
                      case 'waiting_for_submission':
                        loader.startLoading('Requesting confirmation...', true)
                        break
                      case 'submitting':
                        loader.startLoading('Accepting bounty...', true, 2)
                        break
                    }
                  },
                )
                await updateBots()
              } catch (e) {
                console.log(e)
              }
              loader.stopLoading()
            }}>
            {loader.loading?.message || 'ACCEPT BOUNTY'}
          </button>
        </MissionFooter>
      </MissionCompleteReport>
    </BotPageContainer>
  )
}

export default function BotMissionComplete({ bot }: { bot: Bot }) {
  console.log({ bot })
  if (bot.missions[0]?.id === 1) {
    return <JumpComplete bot={bot} />
  }

  return <AirdropMissionComplete bot={bot} />
}
